import { Auth } from 'aws-amplify'
import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import '../../styles/LogOut.css'

interface ILogoutButtons {
    logout: () => void
    back: () => void
}

export const logOutBtn = async () => {
    try {
        await Auth.signOut({ global: true })
        window.location.reload()
    } catch (error) {
        console.log('error signing out: ', error)
    }
}

const LogOut: FC<ILogoutButtons> = ({ logout, back }) => {
    return (
        <div id="logout-container">
            <p id="logout-text">Are you sure you want too log out?</p>
            <Button id="logout-btn" onClick={logout}>
                Logout
            </Button>
            <Button id="back-btn" onClick={back}>
                Back
            </Button>
        </div>
    )
}

export default LogOut
