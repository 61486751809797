import React, { FC } from 'react'
import '../../styles/Prompt.css'

interface IPrompt {
    message: string
}

const Prompt: FC<IPrompt> = ({ message }) => {
    return (
        <div id="prompt-container">
            <h1 id="prompt-text">{message}</h1>
        </div>
    )
}

export default Prompt
