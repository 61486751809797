import React, { FC, useState } from 'react'
import { Button, Container, Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IFeedCandidate } from '../../../interface/actionType'
import { sendFeedCandidate } from '../../../services/BackendCalls'
import '../../../styles/Footer.css'
import { getDate } from '../../shared/getDate'

interface IFooterFunction {
    handleSubmit: (e: React.SyntheticEvent) => void
    savedStatusFromFooter: () => void
    deleteStatusFromFooter: () => void
    createTemplate: (isTemplate: boolean) => void
    disabledSendSaveBtn: boolean
    feedCandidate: IFeedCandidate
}

const Footer: FC<IFooterFunction> = ({
    handleSubmit,
    savedStatusFromFooter,
    deleteStatusFromFooter,
    createTemplate,
    disabledSendSaveBtn,
    feedCandidate,
}) => {
    const [deleteStatus, setDeleteStatus] = useState<boolean>(true)
    const [date, setDate] = useState<any>(new Date(getDate.backendDate()))

    const handleSave = () => {
        savedStatusFromFooter()
    }

    const handleDelete = () => {
        setDeleteStatus(deleteStatus)
        deleteStatusFromFooter()
    }
    const handleHistoric = async () => {
        feedCandidate.feedCandidateStatus = 'HISTORIC'
        const { data } = await sendFeedCandidate(feedCandidate)
    }

    const handleTemplate = () => {
        createTemplate(true)
    }

    const renderButtons = () => {
        if (feedCandidate.feedCandidateStatus === 'ACTIVE') {
            return (
                <Link id="link-historic" to={'/'}>
                    <Button onClick={handleHistoric} id="handleHistoric-btn">
                        Historic
                    </Button>
                </Link>
            )
        } else if (feedCandidate.feedCandidateStatus === 'HISTORIC') {
            return
        } else {
            return (
                <>
                    <Button onClick={handleDelete} id="footer-delete-btn">
                        Delete
                    </Button>
                    <span
                        id="disabled-reset-btn-tip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please add content! :)"
                    >
                        <Button
                            disabled={disabledSendSaveBtn}
                            onClick={handleSave}
                            id="save-btn"
                        >
                            Save
                        </Button>

                        <Button
                            disabled={disabledSendSaveBtn}
                            type="submit"
                            form="layout-form-container"
                            id="send-btn"
                        >
                            Send
                        </Button>
                    </span>
                </>
            )
        }
    }

    return (
        <Navbar id="footer-container">
            <Container
                fluid
                style={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingTop: '0px',
                    marginTop: '0px',
                    backgroundColor: '#251866',
                }}
            >
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Container fluid style={{}} id="form-container">
                        <Nav className="justify-content-left">
                            <p className="the-date">
                                Created:{' '}
                                {date.toLocaleDateString('de-DE', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour24: true,
                                })}{' '}
                                {feedCandidate.feedCandidateTime
                                    ? `// Id: ${feedCandidate.feedCandidateTime}`
                                    : null}
                            </p>
                        </Nav>
                    </Container>
                </Navbar.Collapse>
                <Nav className="ms-auto" id="btns-footer">
                    <Button onClick={handleTemplate} id="template-btn">
                        Template
                    </Button>
                    {renderButtons()}
                </Nav>
            </Container>
        </Navbar>
    )
}

export default Footer
