import React from 'react'

const ErrorView = () => {
    return (
        <div>
            <h2>An error occured..</h2>
            <h3>- Try to refresh</h3>
            <h3>- Ask for help</h3>
        </div>
    )
}

export default ErrorView
