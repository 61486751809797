import React, { FC } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import '../../styles/FooterTiles.css'

interface IFooterTiles {
    actionType: string
    contentType: string
    feedStatus: string
    importance: string
}

const FooterTiles: FC<IFooterTiles> = ({
    actionType,
    contentType,
    feedStatus,
    importance,
}) => {
    return (
        <Navbar id="footer-tiles-container">
            <Container
                id="tiles-footer-container"
                style={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    paddingTop: '0px',
                    marginTop: '0px',
                }}
            >
                <p id="footer-tiles-action">{actionType}</p>
                <p id="footer-tiles-content">{contentType}</p>
                <p id="footer-tiles-status">{feedStatus}</p>
                <p id="footer-tiles-importance">{importance}</p>
            </Container>
        </Navbar>
    )
}

export default FooterTiles

/*<p id="footer-tiles-statistic">STATISTIC</p>*/
