import React from 'react'
import NewFeedCandidateLayout from '../../components/newFeedCandidates/NewFeedCandidateLayout'

const UriLink = () => {
    const form = {
        actionType: 'URI_LINK',
        url: true,
        quiz: false,
        calendar: false,
    }
    return <NewFeedCandidateLayout form={form} />
}

export default UriLink
