/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../styles/InfoPage.css'

const Infopage = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <h1 className={'text-center'}>Info</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Kjente feil</h3>
                    <ul>
                        <li>
                            Når du endrer et selection criteria vil ikke det
                            nederste feltet resette seg
                        </li>
                        <li>
                            På quiz er det ikke støtte i appen enda for "thumbs"
                            og "feedback"
                        </li>
                        <li>
                            Hvis du vil lagre en quiz må det være{' '}
                            <u>minst ett</u> riktig svar.
                        </li>
                        <li>
                            Det er mulig å sette en tidligere sluttdato enn
                            startdato på kalender
                        </li>
                    </ul>
                </Col>
                <Col>
                    <h3>Fikset</h3>
                    <ul>
                        <li>
                            Gir respons når man sender, og feilmelding hvis
                            sending feiler
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default Infopage
