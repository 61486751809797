import React, { FC, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { FeedCandidateContext } from '../../context/FeedCandidateContext'
import { IFeedCandidate } from '../../interface/actionType'
import { FeedCandidateContextType } from '../../types/FeedCandidateContextType'
import FooterTiles from './FooterTiles'
import RenderBasedOnCategory from './RenderBasedOnCategory'
import NewLineText from '../shared/NewLineText'

interface IFeedCandidateItem {
    feedCandidate: IFeedCandidate
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>
    setModalFCandidate: React.Dispatch<React.SetStateAction<IFeedCandidate>>
}

const FeedCandidateItem: FC<IFeedCandidateItem> = ({
    feedCandidate,
    setModalVisible,
    setModalFCandidate,
}) => {
    const { addActiveFeedCandidate } = useContext(
        FeedCandidateContext
    ) as FeedCandidateContextType

    const previewInApp = () => {
        alert('No support for preview of app yet. :/')
    }

    const feedDate = new Date(feedCandidate.feedCandidateTime! + 'z')
    const showFeedDate =
        feedDate.toLocaleDateString() +
        ' ' +
        feedDate.toLocaleTimeString().slice(0, -3)

    const publicDate = new Date(feedCandidate.publicationTime! + 'z')
    let showPublicDate: unknown = {}
    if (feedCandidate.publicationTime === null) {
        showPublicDate = 'Not yet'
    } else {
        showPublicDate =
            publicDate.toLocaleDateString() +
            ' ' +
            publicDate.toLocaleTimeString().slice(0, -3)
    }

    const Item = () => {
        return (
            <>
                <Link
                    id="front-item-link"
                    to={`/newfeedcandidate/${feedCandidate.feedActionType.toLowerCase()}`}
                    onClick={() => addActiveFeedCandidate(feedCandidate)}
                >
                    <h5 id="feed-tile-header-front">{feedCandidate.title}</h5>
                </Link>

                <div id="front-item">
                    <NewLineText
                        id="feed-tile-text-front"
                        text={feedCandidate.text}
                    />
                    <RenderBasedOnCategory feedCandidate={feedCandidate} />
                </div>
            </>
        )
    }

    const TileLayout = () => {
        return (
            <div id="tile-layout">
                <div id="flip-tile-left">
                    <p id="created-date">Create date: {showFeedDate}</p>
                    <p id="released-date">Release date: {showPublicDate}</p>
                    <p id="created-by-text">
                        Created by: {feedCandidate.createdBy}
                    </p>
                </div>
                <div id="flip-tile-right">
                    <span
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Double click!"
                    >
                        <Button
                            id="flip-btn"
                            onClick={() => {
                                // navigate('/statistics', {
                                //     state: { feedCandidate: feedCandidate },
                                // })
                                setModalVisible(true)
                                setModalFCandidate(feedCandidate)
                            }}
                            disabled={feedCandidate.feedActionType !== 'QUIZ'}
                        >
                            Statistic
                        </Button>
                    </span>
                    <Button id="preview-btn" onClick={previewInApp}>
                        Preview
                    </Button>
                </div>
                <hr id="feed-tile-line" />
            </div>
        )
    }

    return (
        <>
            <div id="feed-flip-tile">
                <div id="feed-tile-front">
                    <TileLayout />
                    <Item />
                    <FooterTiles
                        actionType={feedCandidate.feedActionType}
                        contentType={feedCandidate.feedContentType}
                        feedStatus={feedCandidate.feedCandidateStatus}
                        importance={feedCandidate.feedCandidateImportance}
                    />
                </div>
            </div>
        </>
    )
}

export default FeedCandidateItem
