import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { IFeedCandidate, ISendStats, ITypes } from '../interface/actionType'
import httpClient from '../services/httpClient'
import { RootState } from './store'

interface FeedState {
    feedCreated: IFeedCandidate[]
    feedActive: IFeedCandidate[]
    feedHistoric: IFeedCandidate[]
    feedLoading: boolean
    feedTypes: ITypes | null
    feedTypesLoading: boolean
    feedSimulation: ISendStats
    feedSimulationLoading: boolean
}

const initialState: FeedState = {
    feedCreated: [],
    feedActive: [],
    feedHistoric: [],
    feedLoading: false,
    feedTypes: null,
    feedTypesLoading: false,
    feedSimulation: {},
    feedSimulationLoading: false,
}

const namespace = 'feed'

export const getCreatedFeedCandidates = createAsyncThunk(
    `${namespace}/getCreatedFeedCandidates`,
    async () => {
        try {
            const { data } = await httpClient.get(`feed/feedCandidates/CREATED`)
            return data
        } catch (err) {
            console.log(`feed/feedCandidates/created`, err)
        }
    }
)
export const getActiveFeedCandidates = createAsyncThunk(
    `${namespace}/getActiveFeedCandidates`,
    async () => {
        try {
            const { data } = await httpClient.get(`feed/feedCandidates/ACTIVE`)
            return data
        } catch (err) {
            console.log(`feed/feedCandidates/ACTIVE`, err)
        }
    }
)
export const getHistoricFeedCandidates = createAsyncThunk(
    `${namespace}/getHistoricFeedCandidates`,
    async () => {
        try {
            const { data } = await httpClient.get(
                `feed/feedCandidates/HISTORIC`
            )
            return data
        } catch (err) {
            console.log(`feed/feedCandidates/HISTORIC`, err)
        }
    }
)

interface addFeedCandidate {
    feedCandidate: IFeedCandidate
    status: 'CREATED' | 'ACTIVE' | 'HISTORIC'
}

export const addFeedCandidate = createAsyncThunk(
    `${namespace}/addFeedCandidate`,
    async ({ feedCandidate, status }: addFeedCandidate, { dispatch }) => {
        console.log(feedCandidate + ' ' + status)
        try {
            console.log({
                ...feedCandidate,
                feedCandidateStatus: status,
            })
            const { data } = await httpClient.post(`feed/feedCandidate`, {
                ...feedCandidate,
                feedCandidateStatus: status,
            })
            if (status === 'CREATED') {
                dispatch(simulateSend({ id: data.feedCandidateTime }))
            }

            return data
        } catch (err) {
            console.log(`feed/feedCandidates`, err)
        } finally {
            if (status === 'CREATED') {
                dispatch(getCreatedFeedCandidates())
            } else if (status === 'ACTIVE') {
                dispatch(getCreatedFeedCandidates())
                dispatch(getActiveFeedCandidates())
            } else if (status === 'HISTORIC') {
                dispatch(getActiveFeedCandidates())
                dispatch(getHistoricFeedCandidates())
            }
        }
    }
)

export const getFeedTypes = createAsyncThunk(
    `${namespace}/getFeedTypes`,
    async () => {
        try {
            const { data } = await httpClient.get(`feed/types`)
            return data
        } catch (err) {
            console.log(`feed/types`, err)
        }
    }
)

interface simulateSend {
    id: string
}

export const simulateSend = createAsyncThunk(
    `${namespace}/simulateSend`,
    async ({ id }: simulateSend) => {
        try {
            const { data } = await httpClient.get(`feed/simulateSend/${id}`)
            return data
        } catch (err) {
            console.log(`feed/simulateSend/${id}`, err)
        }
    }
)

interface makeHistoric {
    feedCandidates: string[]
}

export const makeHistoric = createAsyncThunk(
    `${namespace}/makeHistoric`,
    async ({ feedCandidates }: makeHistoric) => {
        try {
            const { data } = await httpClient.post(
                `feed/changeToHistoric`,
                feedCandidates
            )
            return data
        } catch (err) {
            console.log(`feed/makeHistoric`, err)
        }
    }
)

const feedSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        resetFeedState: (state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCreatedFeedCandidates.pending, (state) => {
            state.feedLoading = true
        })
        builder.addCase(getCreatedFeedCandidates.fulfilled, (state, action) => {
            state.feedLoading = false
            state.feedCreated = action.payload
        })
        builder.addCase(getCreatedFeedCandidates.rejected, (state) => {
            state.feedLoading = false
        })
        builder.addCase(getActiveFeedCandidates.pending, (state) => {
            state.feedLoading = true
        })
        builder.addCase(getActiveFeedCandidates.fulfilled, (state, action) => {
            state.feedLoading = false
            state.feedActive = action.payload
        })
        builder.addCase(getActiveFeedCandidates.rejected, (state) => {
            state.feedLoading = false
        })
        builder.addCase(getHistoricFeedCandidates.pending, (state) => {
            state.feedLoading = true
        })
        builder.addCase(
            getHistoricFeedCandidates.fulfilled,
            (state, action) => {
                state.feedLoading = false
                state.feedHistoric = action.payload
            }
        )
        builder.addCase(getHistoricFeedCandidates.rejected, (state) => {
            state.feedLoading = false
        })
        builder.addCase(getFeedTypes.pending, (state) => {
            state.feedTypesLoading = true
        })
        builder.addCase(getFeedTypes.fulfilled, (state, action) => {
            state.feedTypesLoading = false
            state.feedTypes = action.payload
        })
        builder.addCase(getFeedTypes.rejected, (state) => {
            state.feedTypesLoading = false
        })

        builder.addCase(addFeedCandidate.pending, (state) => {
            state.feedLoading = true
        })
        builder.addCase(addFeedCandidate.fulfilled, (state, { payload }) => {
            state.feedLoading = false
            if (payload.status === 'CREATED') {
                state.feedCreated.push(payload)
            } else if (payload.status === 'ACTIVE') {
                state.feedActive.push(payload)
            } else if (payload.status === 'HISTORIC') {
                state.feedHistoric.push(payload)
            }
        })
        builder.addCase(addFeedCandidate.rejected, (state) => {
            state.feedLoading = false
        })
        builder.addCase(simulateSend.pending, (state) => {
            state.feedSimulationLoading = true
        })
        builder.addCase(simulateSend.fulfilled, (state, { payload }) => {
            state.feedSimulationLoading = false
            state.feedSimulation = payload
        })
        builder.addCase(simulateSend.rejected, (state) => {
            state.feedSimulationLoading = false
        })
        builder.addCase(makeHistoric.fulfilled, (state, { payload }) => {
            if (payload.length) {
                state.feedActive = payload.filter(
                    (feedCandidate: IFeedCandidate) =>
                        feedCandidate.feedCandidateStatus === 'ACTIVE'
                )
                state.feedHistoric = payload.filter(
                    (feedCandidate: IFeedCandidate) =>
                        feedCandidate.feedCandidateStatus === 'HISTORIC'
                )
            }
            state.feedLoading = false
        })
    },
})
export const { resetFeedState } = feedSlice.actions
export const useCreatedFeedCandidates = () =>
    useSelector((state: RootState) => state.feed.feedCreated)
export const useActiveFeedCandidates = () =>
    useSelector((state: RootState) => state.feed.feedActive)
export const useHistoricFeedCandidates = () =>
    useSelector((state: RootState) => state.feed.feedHistoric)
export const useFeedLoading = () =>
    useSelector((state: RootState) => state.feed.feedLoading)
export const useFeedTypes = () =>
    useSelector((state: RootState) => state.feed.feedTypes)
export const useFeedTypesLoading = () =>
    useSelector((state: RootState) => state.feed.feedTypesLoading)
export const useFeedSimulation = () =>
    useSelector((state: RootState) => state.feed.feedSimulation)
export const useFeedSimulationLoading = () =>
    useSelector((state: RootState) => state.feed.feedSimulationLoading)

export default feedSlice.reducer
