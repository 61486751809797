import React, { FC } from 'react'
import { ICalendar, IFeedCandidate } from '../../interface/actionType'
import { supportFunctions } from '../shared/supportFunctions'

interface IRenderBasedOnCategory {
    feedCandidate: IFeedCandidate
}

const RenderBasedOnCategory: FC<IRenderBasedOnCategory> = ({
    feedCandidate,
}) => {
    const renderCategory1 = (feedCandidate: IFeedCandidate) => {
        return (
            <a id="feed-target" href={`${feedCandidate.target}`}>
                {feedCandidate.target !== '' ? feedCandidate.target : ''}
            </a>
        )
    }

    const renderCategory2 = (feedCandidate: IFeedCandidate) => {
        return (
            <>
                {feedCandidate.candidateAnswer?.map((answer, key) => {
                    function chooseTypes(type: string) {
                        switch (type) {
                            case 'CHOOSE':
                                if (
                                    answer.answerType === 'CHOOSE_ONE' ||
                                    answer.answerType === 'CHOOSE_MANY'
                                ) {
                                    return <p key={key}>{answer.answerType}</p>
                                }
                                break
                            case 'THUMBS':
                                if (answer.answerType === 'THUMBS') {
                                    return <p key={key}>{answer.answerType}</p>
                                }
                                break
                            case 'FEEDBACK':
                                if (answer.answerType === 'FEEDBACK') {
                                    return <p key={key}>{answer.answerType}</p>
                                }
                                break
                        }
                    }
                    return (
                        <div key={key} id="tile-quiz-list">
                            <div id="feed-choose">{chooseTypes('CHOOSE')}</div>
                            {answer.answerOptions?.map((answer, key) => {
                                return (
                                    <li
                                        key={key}
                                        style={{
                                            color: answer.correct
                                                ? '#9ad061'
                                                : '#ef0119',
                                        }}
                                    >
                                        {answer.answer}
                                    </li>
                                )
                            })}
                            <div id="thumb-feedback">
                                {chooseTypes('THUMBS')}
                                {chooseTypes('FEEDBACK')}
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    const renderCategory3 = (feedCandidate: IFeedCandidate) => {
        let dateObject: ICalendar = {
            title: '',
            startDate: '',
            endDate: '',
            location: '',
            allDay: false,
        }
        try {
            dateObject = JSON.parse(feedCandidate.target)
        } catch (e) {
            console.log(e)
        }

        return (
            <ul id="date-list">
                <li>
                    From:{' '}
                    {supportFunctions.ISOtoReadable(
                        dateObject.startDate,
                        dateObject.allDay
                    )}
                </li>
                <li>
                    To:{' '}
                    {supportFunctions.ISOtoReadable(
                        dateObject.endDate,
                        dateObject.allDay
                    )}
                </li>
                <li>
                    To:{' '}
                    {supportFunctions.ISOtoReadable(
                        dateObject.endDate,
                        dateObject.allDay
                    )}
                </li>
                <li>All day: {dateObject.allDay ? 'Yes' : 'No'}</li>
                <li>Location: {dateObject.location}</li>
            </ul>
        )
    }

    const renderItemCategory = () => {
        switch (feedCandidate.feedActionType) {
            case 'ARTICLE':
            case 'INFO':
                return renderCategory1(feedCandidate)

            case 'QUIZ':
            case 'QUESTION':
                return renderCategory2(feedCandidate)

            case 'CALENDAR_ADD':
                return renderCategory3(feedCandidate)
            default:
                // eslint-disable-next-line react/no-unescaped-entities
                return <p>An error occured, can't render...</p>
        }
    }

    return renderItemCategory()
}

export default RenderBasedOnCategory
