import React, { FC, useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FeedTypesContext } from '../../context/FeedTypesContext'
import { FeedTypesContextType } from '../../types/FeedTypesContextType'

export const substitionList = {
    firstName: '{{FIRST_NAME}}',
    lastName: '{{LAST_NAME}}',
    numberOfBanks: '{{NUM_BANKS}}',
    lastLoggedIn: '{{LAST_LOGGED_IN_DATE}}',
}
interface Props {
    onPress: (substitute: string, target: 'text' | 'title') => void
    target: 'text' | 'title'
}

const SubstituteSelector: FC<Props> = ({ onPress, target }) => {
    const { feedTypes } = useContext(FeedTypesContext) as FeedTypesContextType

    return (
        <div style={{ paddingBottom: 5 }}>
            <Dropdown>
                <Dropdown.Toggle variant="info" id="dropdown-basic" size="sm">
                    Select substitute
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {feedTypes.substitutionTags.map((value) => (
                        <Dropdown.Item
                            onClick={() => onPress(`{{${value}}}`, target)}
                            key={value}
                        >
                            {value}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default SubstituteSelector

// {Object.keys(substitionList).map((value) => (
//     <Dropdown.Item
//         onClick={() =>
//             onPress(
//                 substitionList[
//                     value as keyof typeof substitionList
//                 ],
//                 target
//             )
//         }
//         key={value}
//     >
//         {value}
//     </Dropdown.Item>
