import React, { FC, useState } from 'react'
import { IActionType } from '../../../interface/actionType'
import NewFeedCandidateItem from './NewFeedCandidateItem'

const NewFeedCandidatesList: FC = () => {
    const [actionType, setActionType] = useState<IActionType[]>([
        { name: 'Article' },
        { name: 'Info' },
        { name: 'In-appLink' },
        { name: 'Question' },
        { name: 'Calendar_add' },
        { name: 'Uri_link' },
    ])

    return (
        <>
            {actionType.map((name: IActionType, key: number) => {
                return (
                    <div id="card" key={key}>
                        <NewFeedCandidateItem name={name.name} />
                    </div>
                )
            })}
        </>
    )
}

export default NewFeedCandidatesList
