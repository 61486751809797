import React, { createContext, FC, useEffect, useState } from 'react'
import { ITypes } from '../interface/actionType'
import { getTypes } from '../services/BackendCalls'
import { FeedTypesContextType } from '../types/FeedTypesContextType'

export const FeedTypesContext = createContext<FeedTypesContextType | null>(null)

export const FeedTypesProvider: FC = ({ children }) => {
    const [feedTypes, setFeedTypes] = useState<ITypes>({
        feedActionTypes: [],
        feedContentTypes: [],
        criteriaCodes: [],
        criteriaOperators: [],
        criteriaTypes: [],
        answerType: [],
        profileDataTypes: [],
        substitutionTags: [],
        feedContextValues: [],
    })

    useEffect(() => {
        getFeedTypes()
    }, [])

    const getFeedTypes = async () => {
        const res = await getTypes()
        if (res) setFeedTypes(res)
    }

    return (
        <>
            <FeedTypesContext.Provider
                value={{
                    feedTypes,
                }}
            >
                {children}
            </FeedTypesContext.Provider>
        </>
    )
}
