import React, {
    useEffect,
    useState,
    FC,
    useCallback,
    useContext,
    useMemo,
} from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FeedTypesContext } from '../../context/FeedTypesContext'
import {
    IAnswer,
    ICalendar,
    ICandidateAnswer,
    IFeedCandidate,
} from '../../interface/actionType'
import {
    addFeedCandidate,
    useFeedLoading,
    useFeedSimulation,
    useFeedSimulationLoading,
} from '../../redux/feedSlice'
import { AppDispatch } from '../../redux/store'
import { sendFeedCandidate, simulateSend } from '../../services/BackendCalls'
import '../../styles/Summary.css'
import { FeedTypesContextType } from '../../types/FeedTypesContextType'
import Prompt from './Prompt'
import { log } from 'console'

interface ISummary {
    feedCandidate: IFeedCandidate
    setFeedCandidate: (feedCandidate: IFeedCandidate) => void
}

const Summary: FC<ISummary> = ({ feedCandidate, setFeedCandidate }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [showPrompt, setShowPrompt] = useState<boolean>(false)
    const [promptText, setPromptText] = useState('')

    const [simulation, setSimulation] = useState<number>(0)
    const { feedTypes } = useContext(FeedTypesContext) as FeedTypesContextType
    const feedLoading = useFeedLoading()
    const feedSimulation = useFeedSimulation()
    const feedSimulationLoading = useFeedSimulationLoading()
    const dispatch = useDispatch<AppDispatch>()

    const navigate = useNavigate()

    const onLaunch = async () => {
        console.log(feedCandidate)
        const newPublicationTime = feedCandidate.plannedPublishTime?.substring(
            0,
            feedCandidate.plannedPublishTime.length - 1
        )
        const feedCandidatePublishFix = {
            ...feedCandidate,
            plannedPublishTime: newPublicationTime,
        }
        console.log(feedCandidatePublishFix)

        dispatch(
            addFeedCandidate({
                feedCandidate: feedCandidatePublishFix,
                status: 'CREATED',
            })
        )
        setFeedCandidate(feedCandidatePublishFix)
    }

    useEffect(() => {
        onLaunch()
    }, [])

    const send = async () => {
        setPromptText('Sending')
        setShowPrompt(true)

        dispatch(
            addFeedCandidate({
                feedCandidate,
                status: 'ACTIVE',
            })
        )

        setTimeout(() => {
            setShowPrompt(false)
            navigate('/')
        }, 1000)
    }

    const handleTarget = () => {
        if (feedCandidate.feedActionType === 'CALENDAR_ADD') {
            const parsed: ICalendar = JSON.parse(feedCandidate.target)
            const parsedStartDate = new Date(parsed.startDate)
            const parsedEndDate = new Date(parsed.endDate)
            return (
                <>
                    <h3 id="calendar-target">Target: {parsed.title}</h3>
                    <h3 id="location-target">{parsed.location}</h3>
                    <h4 id="start-target">
                        {parsedStartDate.toLocaleDateString('de-DE', {
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                    </h4>
                    <h4 id="end-target">
                        {parsedEndDate.toLocaleDateString('de-DE', {
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                    </h4>
                    <h4 id="allday-target">
                        AllDay:{parsed.allDay ? <div>Yes</div> : <div>No</div>}
                    </h4>
                </>
            )
        } else {
            return <h3 id="s-target">Target: {feedCandidate.target}</h3>
        }
    }

    const simulationPercentage = useMemo(() => {
        if (
            !feedSimulation.numQualifiedReceiversFeed ||
            !feedSimulation.totalNumUsersSimulated
        )
            return 102
        const { numQualifiedReceiversFeed, totalNumUsersSimulated } =
            feedSimulation
        if (numQualifiedReceiversFeed && totalNumUsersSimulated) {
            return (
                (parseInt(numQualifiedReceiversFeed) /
                    parseInt(totalNumUsersSimulated)) *
                100
            )
        }
        return 101
    }, [feedSimulation])

    const checkForSubstitute = useCallback(
        (txt: string) => {
            let validatedTxt = txt
            feedTypes.substitutionTags.forEach((value) => {
                if (txt.includes(value)) {
                    validatedTxt = validatedTxt.replaceAll(
                        `{{${value}}}`,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        ` **${feedTypes.substitutionTags.find(
                            (key) => key === value
                        )!}**`
                    )
                }
            })
            return validatedTxt
        },
        [feedCandidate.title, feedCandidate.text]
    )

    return (
        <div id="summary-container">
            {showPrompt && <Prompt message={promptText} />}
            <h1 id="summary-header">
                {checkForSubstitute(feedCandidate.title)}
            </h1>
            <h3 id="summary-text">
                {feedCandidate.text
                    ? checkForSubstitute(feedCandidate.text)
                    : null}
            </h3>
            <div id="summary-target">{handleTarget()}</div>
            <div id="type-important">
                <h3 id="summary-type">Type: {feedCandidate.feedContentType}</h3>
                <h3 id="summary-important">
                    {feedCandidate.feedCandidateImportance === 'IMPORTANT'
                        ? 'Important'
                        : 'Not important'}
                </h3>
            </div>
            <div>
                <h3 id="summary-channel">
                    Channel: {feedCandidate.candidateChannel}
                </h3>
            </div>
            <div>
                <h3 id="summary-channel">
                    Context: {feedCandidate.feedContextList}
                </h3>
            </div>
            {feedCandidate.candidateAnswer &&
                feedCandidate.candidateAnswer.map(
                    (answer: ICandidateAnswer, key: number) => {
                        function chooseTypes(type: string) {
                            switch (type) {
                                case 'CHOOSE':
                                    if (
                                        answer.answerType === 'CHOOSE_ONE' ||
                                        answer.answerType === 'CHOOSE_MANY'
                                    ) {
                                        return <h4>{answer.answerType}</h4>
                                    }
                                    break
                                case 'THUMBS':
                                    if (answer.answerType === 'THUMBS') {
                                        return <h4>{answer.answerType}</h4>
                                    }
                                    break
                                case 'FEEDBACK':
                                    if (answer.answerType === 'FEEDBACK') {
                                        return <h4>{answer.answerType}</h4>
                                    }
                                    break
                            }
                        }
                        if (answer.answerType !== undefined) {
                            return (
                                <div key={key} id="summary-quiz-container">
                                    <div id="summary-choose">
                                        {chooseTypes('CHOOSE')}
                                    </div>
                                    <div id="summary-thumbs">
                                        {chooseTypes('THUMBS')}
                                    </div>
                                    <div id="summary-feedback">
                                        {chooseTypes('FEEDBACK')}
                                    </div>
                                    {answer.answerOptions &&
                                        answer.answerOptions.map(
                                            (
                                                answerOptions: IAnswer,
                                                key: number
                                            ) => {
                                                return (
                                                    <div
                                                        id="summmary-quiz"
                                                        key={key}
                                                    >
                                                        <h4>
                                                            Answer {key + 1}:{' '}
                                                            {
                                                                answerOptions.answer
                                                            }
                                                        </h4>
                                                        <div id="quiz-answers">
                                                            {answerOptions.correct ? (
                                                                <h4
                                                                    style={{
                                                                        color: '#9ad061',
                                                                    }}
                                                                >
                                                                    (Right)
                                                                </h4>
                                                            ) : (
                                                                <h4
                                                                    style={{
                                                                        color: '#d20117',
                                                                    }}
                                                                >
                                                                    (Wrong)
                                                                </h4>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                </div>
                            )
                        } else {
                            return (
                                <h3 id="summary-question" key={key}>
                                    Question type: Not a quiz or profiling.
                                </h3>
                            )
                        }
                    }
                )}
            <div id="recieved-summarybtn">
                {feedLoading ? (
                    <Spinner animation={'border'} />
                ) : (
                    <>
                        <h3 id="summary-received">
                            {simulationPercentage
                                ? `Will be received by: ${Math.ceil(
                                      simulationPercentage
                                  )}%`
                                : feedSimulationLoading
                                ? 'Loading simulation..'
                                : 'Error when trying to simulate'}
                        </h3>

                        <Button
                            disabled={feedLoading}
                            id="summary-btn"
                            onClick={send}
                        >
                            Send
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

export default Summary
