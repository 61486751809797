import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import {
    IFeedCandidate,
    IFeedCandidateAnswer,
} from '../../interface/actionType'
import { getFeedCandidateAnswer } from '../../services/BackendCalls'
import LoadingView from '../shared/LoadingView'

interface Props {
    feedCandidate: IFeedCandidate
    show: boolean
    onHide: () => void
}

const StatisticItem: FC<Props> = ({ feedCandidate, show, onHide }) => {
    const [loading, setLoading] = useState(false)
    const [answers, setAnswers] = useState<IFeedCandidateAnswer[]>([])

    const getAnswers = async () => {
        try {
            setLoading(true)
            const res = await getFeedCandidateAnswer(
                feedCandidate.feedCandidateTime!
            )
            setAnswers(res)
        } catch (e) {
            setLoading(false)
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const answeredList = useMemo(() => {
        if (answers.length === 0) return []
        const answeredArray: string[] = []
        answers.forEach((answer) => {
            answer.feedItemAnswerPost.forEach((item) => {
                item.answerId.forEach((item) => {
                    answeredArray.push(item)
                })
            })
        })

        return answeredArray
    }, [answers])

    useEffect(() => {
        if (feedCandidate.feedActionType === 'QUIZ') {
            if (
                feedCandidate.candidateAnswer &&
                feedCandidate.candidateAnswer[0]
            ) {
                getAnswers()
            }
        }
    }, [feedCandidate])

    const countNum = (a: string, b: string[]) => {
        return b.filter((x) => x === a).length
    }

    if (loading) {
        return <LoadingView />
    }
    if (!feedCandidate) {
        return <div>No feed candidate</div>
    }
    const Showthis = () => {
        const unique = () => {
            const arr: string[] = []
            answeredList.forEach((item) => {
                if (!arr.includes(item)) {
                    arr.push(item)
                }
            })
            return arr
        }
        const findAnswerText = (id: string) => {
            if (
                feedCandidate.candidateAnswer &&
                feedCandidate.candidateAnswer[0]
            ) {
                const candidateAnswer = feedCandidate.candidateAnswer[0]
                if (candidateAnswer.answerOptions) {
                    const answerText = candidateAnswer.answerOptions.find(
                        (item) => {
                            item.answerId === id
                            return item
                        }
                    )
                    return answerText
                }
            } else return null
        }

        return (
            <div style={{ width: '100%', padding: '5%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <p>Alternativ</p>
                    <p>Antall svar</p>
                </div>

                {unique().map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                backgroundColor: '#f5f5f5',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                display: 'flex',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                        >
                            <p
                                style={{
                                    color: findAnswerText(item)?.correct
                                        ? 'green'
                                        : '',
                                }}
                            >
                                {findAnswerText(item)?.answer}
                            </p>
                            <p>{countNum(item, answeredList)}</p>
                        </div>
                    )
                })}
            </div>
        )
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {feedCandidate.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {answeredList.length ? <Showthis /> : <p>Ingen svar</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default StatisticItem
