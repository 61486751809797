import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import DateTimePicker from 'react-datetime-picker'
import { ICalendar } from '../../../interface/actionType'
import '../../../styles/DateAndTime.css'

interface IDateAndTime {
    calendarItem: ICalendar
    setCalendarItem: (date: ICalendar) => void
}

const DateAndTime: FC<IDateAndTime> = ({ calendarItem, setCalendarItem }) => {
    return (
        <div id="date-container">
            <p id="datepicker-header">Start date {calendarItem.startDate}</p>
            <DateTimePicker
                className={'date-picker'}
                disableClock={true}
                onChange={(value) =>
                    setCalendarItem({
                        ...calendarItem,
                        startDate: value.toISOString(),
                    })
                }
                value={new Date(calendarItem.startDate)}
            />
            <p id="datepicker-header">End date</p>
            <DateTimePicker
                className={'date-picker'}
                disableClock={true}
                onChange={(value) =>
                    setCalendarItem({
                        ...calendarItem,
                        endDate: value.toISOString(),
                    })
                }
                value={new Date(calendarItem.endDate)}
            />
            <div id="all-day">
                <Form.Check
                    className="checkbox"
                    checked={calendarItem.allDay}
                    id="all-day-check"
                    type={'checkbox'}
                    name={'allDay'}
                    onChange={() =>
                        setCalendarItem({
                            ...calendarItem,
                            allDay: !calendarItem.allDay,
                        })
                    }
                />
                <label id="all-day-label">All day</label>
            </div>
            <p id="location-header">Location</p>
            <input
                id="location-input"
                type={'text'}
                value={calendarItem.location}
                placeholder={'Enter location'}
                onChange={(e) =>
                    setCalendarItem({
                        ...calendarItem,
                        location: e.target.value,
                    })
                }
            />
        </div>
    )
}

export default DateAndTime
