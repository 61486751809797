import React, { FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainNavigation from '../components/shared/MainNavigation'

import Home from '../pages/Home'
import FeedCandidates from '../pages/FeedCandidates'
import NewFeedCandidate from '../pages/NewFeedCandidate'
import Statistics from '../pages/Statistics'
import Article from '../pages/NewFeedCandidates/Article'
import CreateTask from '../pages/NewFeedCandidates/CreateTask'
import InAppLink from '../pages/NewFeedCandidates/InAppLink'
import Question from '../pages/NewFeedCandidates/Question'
import Info from '../pages/NewFeedCandidates/Info'
import Calendar from '../pages/NewFeedCandidates/Calendar'
import UriLink from '../pages/NewFeedCandidates/UriLink'
import Infopage from '../pages/Infopage'

const Routing: FC = () => {
    return (
        <BrowserRouter>
            <MainNavigation />
            <Routes>
                <Route path={'/'} element={<Home />} />
                <Route path={'/feedcandidates'} element={<FeedCandidates />} />
                <Route
                    path={'/newfeedcandidate'}
                    element={<NewFeedCandidate />}
                />
                <Route path={'/statistics'} element={<Statistics />} />
                <Route
                    path={'/newfeedcandidate/article'}
                    element={<Article />}
                />
                <Route path={'/newfeedcandidate/info'} element={<Info />} />
                <Route
                    path={'/newfeedcandidate/createtask'}
                    element={<CreateTask />}
                />
                <Route
                    path={'/newfeedcandidate/in-applink'}
                    element={<InAppLink />}
                />
                <Route
                    path={'/newfeedcandidate/question'}
                    element={<Question />}
                />
                <Route path={'/newfeedcandidate/quiz'} element={<Question />} />
                <Route
                    path={'/newfeedcandidate/calendar_add'}
                    element={<Calendar />}
                />
                <Route
                    path={'/newfeedcandidate/uri_link'}
                    element={<UriLink />}
                />
                <Route path={'/info'} element={<Infopage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Routing
