import Axios, { AxiosResponse } from 'axios'
import { Auth, Hub } from 'aws-amplify'
import awsExports from '../aws-exports.js'
import { IFeedCandidate } from '../interface/actionType.js'
import httpClient, { getToken } from './httpClient'
Auth.configure(awsExports)

export const getTypes = async () => {
    try {
        const { data } = await httpClient.get(`feed/types`)
        return data
    } catch (e) {
        console.log(e)
    }
}
export const getProfilingAnswers = async () => {
    try {
        const { data } = await httpClient.get(`feed/types`)
        return data.profileDataTypes
    } catch (e) {
        console.log(e)
    }
}

export const getFeedCandidates = async (url: string) => {
    try {
        const { data } = await httpClient.get(`feed/feedCandidates/${url}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const simulateSend = async (id: string) => {
    try {
        const { data } = await httpClient.get(`feed/simulateSend/${id}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getUserData = async () => {
    try {
        const { data } = await httpClient.get(`user`)
        return JSON.parse(JSON.parse(JSON.parse(data.feedAdminData)))
    } catch (e) {
        console.log(e)
    }
}

export const getEmail = async () => {
    try {
        const { data } = await httpClient.get(`user`)
        return data.email
    } catch (e) {
        console.log(e)
    }
}
export const updateUserData = async (updatedData: string) => {
    try {
        const { data } = await httpClient.put(`user`, {
            feedAdminData: updatedData,
        })
        return data
    } catch (e) {
        console.log(e)
    }
}
export const getLoginData = async () => {
    try {
        const { data } = await httpClient.get(`stats/usersLoggedInDaily`)
        return data
    } catch (e) {
        console.log(e)
    }
}

const testLogFeeds = async () => {
    // const active = await getFeedCandidates('ACTIVE')
    // const created = await getFeedCandidates('CREATED')
    // const historic = await getFeedCandidates('HISTORIC')
    // const feedTypes = await getFeedContentTypes()
    // const action = await getFeedCriteriaCodes()
    const token = await getToken()
    //const userData = await getUserData()
    //const pof = await getProfilingAnswers()
    //const email = await getEmail()
    // const loginData = await getLoginData()
    // getTypes()

    console.log(token)
    // console.log(loginData);

    // console.log(updatedd);
    // console.log(pof);
    // console.log(active)
    // console.log(action)
    // console.log(historic)
    // console.log(created)
    // console.log(feedTypes)
    //console.log(userData)
    //console.log(email);
}

testLogFeeds()

//TODO: Error handle
export const sendFeedCandidate = async (feedCandidate: IFeedCandidate) => {
    console.log(feedCandidate)

    return httpClient
        .post(`feed/feedCandidate`, feedCandidate)
        .then((res) => {
            console.log(res)
            return res
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data)
            }
            return error
        })
}
//TODO: Delete this
// export const sendFeedCandidate = async (feedCandidate: IFeedCandidate) => {
//     return Axios.post('feed/feedCandidate', feedCandidate, {
//         headers: {
//             Authorization: await getToken(),
//             'Content-Type': 'application/json; charset=utf-8',
//         },
//     })
//         .then((res) => {
//             console.log(res)

//             // return res.data as IFeedCandidate
//             return res
//         })
//         .catch((error) => {
//             if (error.response) {
//                 console.log(error.response.data)
//             }
//             return error
//         })
// }

export const deleteFeedCandidate = async (id: string) => {
    try {
        const { data } = await httpClient.delete(`feed/feedCandidate/${id}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getFeedCandidateById = async (id: string) => {
    try {
        const { data } = await httpClient.get(`feed/feedCandidate/${id}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

export const getFeedCandidateAnswer = async (id: string) => {
    try {
        const { data } = await httpClient.get(`feed/feedCandidateAnswer/${id}`)
        return data
    } catch (e) {
        console.log(e)
    }
}

Hub.listen('auth', (data) => {
    const { payload } = data
    if (payload.event === 'signOut') {
        console.log('signOut')
    } else if (payload.event === 'signIn') {
        console.log(
            'Auth event has happened: ',
            data.payload.data.username + ' has signed in!'
        )
    }
})
