import React, { FC } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Routing from './routing/Routing'
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import './styles/App.css'
import { FeedCandidateProvider } from './context/FeedCandidateContext'
import { FeedTypesProvider } from './context/FeedTypesContext'
import { Provider } from 'react-redux'
import store from './redux/store'
import { ThemeProvider } from 'react-bootstrap'
import theme from './lib/theme'

Amplify.configure(awsExports)

const App: FC = () => {
    return (
        <Provider store={store}>
            <div className="App">
                <FeedCandidateProvider>
                    <FeedTypesProvider>
                        <ThemeProvider theme={theme}>
                            <Routing />
                        </ThemeProvider>
                    </FeedTypesProvider>
                </FeedCandidateProvider>
            </div>
        </Provider>
    )
}

export default withAuthenticator(App)
