import React, { createContext, FC, useState } from 'react'
import { IFeedCandidate } from '../interface/actionType'
import { FeedCandidateContextType } from '../types/FeedCandidateContextType'

export const FeedCandidateContext =
    createContext<FeedCandidateContextType | null>(null)

export const emptyCandidate = {
    feedCandidateTime: '',
    feedActionType: '',
    title: 'Denne skal du aldri se',
    text: 'Kanskje du ser den allikevel? ;)',
    target: '',
    feedCandidateStatus: '',
    feedContentType: 'BASIC_KNOWLEDGE',
    feedCandidateImportance: 'IMPORTANT',
    selectionCriteriaList: [
        {
            criteriaCode: 'Første valget',
            operator: '>',
            operand: 'Andre valget',
        },
        {
            criteriaCode: 'Andre valget',
            operator: '<',
            operand: 'Første valget',
        },
    ],
    candidateChannel: '',
    createdBy: 'Erik Andreas',
    publicationTime: '',
    feedContextList: [],
    plannedPublishTime: '',
}

export const FeedCandidateProvider: FC = ({ children }) => {
    const [savedFeedCandidate, setSavedFeedCandidate] =
        useState<IFeedCandidate>(emptyCandidate)

    const addActiveFeedCandidate = (newCandidate: IFeedCandidate) => {
        setSavedFeedCandidate(newCandidate)
    }
    const removeActiveFeedCandidate = () => {
        setSavedFeedCandidate(emptyCandidate)
    }

    return (
        <>
            <FeedCandidateContext.Provider
                value={{
                    savedFeedCandidate,
                    addActiveFeedCandidate,
                    removeActiveFeedCandidate,
                }}
            >
                {children}
            </FeedCandidateContext.Provider>
        </>
    )
}
