export const getDate = (function () {
    const current = new Date()

    const year = current.getFullYear()
    const month = ('0' + (current.getMonth() + 1)).slice(-2)
    const day = ('0' + current.getDate()).slice(-2)

    const hour = ('0' + current.getHours()).slice(-2)
    const min = ('0' + current.getMinutes()).slice(-2)
    const sec = ('0' + current.getSeconds()).slice(-2)
    const mill = ('00' + current.getMilliseconds()).slice(-3)

    const backendDate = () => {
        const currentDate = `${year}-${month}-${day}T${hour}:${min}:${sec}.${mill}`
        return currentDate
    }

    const displayDate = () => {
        const currentDate = `${day}/${month} - ${hour}:${min}`
        return currentDate
    }
    return {
        backendDate,
        displayDate,
    }
})()
