import React, { FC } from 'react'

interface Props {
    id?: string
    className?: string
    text?: string
}

const NewLineText: FC<Props> = ({ id, className, text }) => {
    if (!text) {
        return <></>
    } else {
        return (
            <>
                {text.split('\n').map((str, index) => (
                    <p key={index} id={id} className={className}>
                        {str}
                    </p>
                ))}
            </>
        )
    }
}

export default NewLineText
