import React, { FC } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { ICriterium } from '../../../interface/actionType'

interface ICriteriumItem {
    criterium: ICriterium
    deleteCriterium: (index: number) => void
    index: number
}

const CriteriumItem: FC<ICriteriumItem> = ({
    criterium,
    deleteCriterium,
    index,
}) => {
    return (
        <li id="criteria-li">
            {criterium.criteriaCode} {criterium.operator} {criterium.operand}
            <Button id="delete-criteria" onClick={() => deleteCriterium(index)}>
                Delete
            </Button>
        </li>
    )
}

export default CriteriumItem
