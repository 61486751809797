import React, { useEffect, useState } from 'react'
import FeedCandidateItem from '../components/listFeedCandidates/FeedCandidateItem'
import { IFeedCandidate, ILoginData } from '../interface/actionType'
import { getLoginData } from '../services/BackendCalls'
import '../styles/Home.css'
import {
    LineChart,
    ResponsiveContainer,
    Legend,
    Tooltip,
    Line,
    XAxis,
    CartesianGrid,
} from 'recharts'
import useApi from '../hooks/useApi'
import LoadingView from '../components/shared/LoadingView'
import ErrorView from '../components/shared/ErrorView'
import { emptyCandidate } from '../context/FeedCandidateContext'
import {
    getCreatedFeedCandidates,
    useCreatedFeedCandidates,
    useFeedLoading,
} from '../redux/feedSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../redux/store'

const Home = () => {
    const [modalVisible, setModalVisible] = useState(false)
    const [modalFCandidate, setModalFCandidate] =
        useState<IFeedCandidate>(emptyCandidate)

    const feedCreated = useCreatedFeedCandidates()
    const feedLoading = useFeedLoading()
    const dispatch = useDispatch<AppDispatch>()

    const {
        data: loginDates,
        loading: loginLoading,
        error: loadingError,
        request: populateLogin,
    } = useApi<ILoginData[]>(getLoginData)

    useEffect(() => {
        populateLogin()
        if (!feedCreated.length) {
            dispatch(getCreatedFeedCandidates())
        }
    }, [])

    const renderDailyGraph = () => {
        return (
            <>
                <p id="graph-header">
                    Logins last 10 weeks in prod environment
                </p>
                <ResponsiveContainer id="graph-body" width="100%" aspect={3}>
                    <LineChart data={loginDates}>
                        <CartesianGrid />
                        <XAxis dataKey="date" interval={'preserveStartEnd'} />
                        <Legend />
                        <Tooltip />
                        <Line
                            dataKey="userCount"
                            stroke="black"
                            activeDot={{ r: 8 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </>
        )
    }

    return (
        <div id="home-container">
            <h1 id="home-header">Home</h1>
            <hr id="home-line" />
            <h2 id="home-tile-header">Recent created</h2>
            {feedLoading && <LoadingView />}
            {!feedCreated && !feedLoading && <ErrorView />}
            {!!feedCreated.length && !feedLoading && (
                <div id="home-tile-container">
                    {feedCreated
                        .slice(0, 3)
                        .map((feedCandidate: IFeedCandidate, key: number) => {
                            return (
                                <FeedCandidateItem
                                    setModalFCandidate={setModalFCandidate}
                                    setModalVisible={setModalVisible}
                                    key={key}
                                    feedCandidate={feedCandidate}
                                />
                            )
                        })}
                </div>
            )}
            <hr id="home-line" />
            <h2 id="home-login-header">Logins</h2>
            <div id="home-graph">
                {loginLoading && <LoadingView />}
                {loadingError && <ErrorView />}
                {renderDailyGraph()}
            </div>
        </div>
    )
}

export default Home
