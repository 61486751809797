import { Auth } from 'aws-amplify'
import Axios, { AxiosResponse } from 'axios'
import awsExports from '../aws-exports'
import { isProd } from '../lib/constants'
Auth.configure(awsExports)

const devApi = 'https://apitest.visonomic.no/backend-dev'
const testApi = 'https://api.visonomic.no/backend'
const prodAPI = 'https://api.visonomic.no/40'

export const getToken = async () => {
    return Auth.currentSession().then((data) => {
        return data.getIdToken().getJwtToken()
    })
}

const httpClient = Axios.create({
    baseURL: process.env.REACT_APP_API_ADDRESS
        ? process.env.REACT_APP_API_ADDRESS
        : isProd
        ? prodAPI
        : devApi,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
})

httpClient.interceptors.request.use(async (config) => {
    const token = await getToken()
    if (!config.headers) config.headers = {}
    config.headers.Authorization = token
    return config
})

export default httpClient
