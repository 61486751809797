import React from 'react'
import NewFeedCandidateLayout from '../../components/newFeedCandidates/NewFeedCandidateLayout'

const Calendar = () => {
    const form = {
        actionType: 'CALENDAR_ADD',
        url: false,
        quiz: false,
        calendar: true,
    }
    return <NewFeedCandidateLayout form={form} />
}

export default Calendar
