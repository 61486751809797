import { ITypes } from '../../interface/actionType'
import { getTypes } from '../../services/BackendCalls'

export const supportFunctions = (function () {
    const current = new Date()
    const year = current.getFullYear()
    const month = ('0' + (current.getMonth() + 1)).slice(-2)
    const day = ('0' + current.getDate()).slice(-2)

    const hour = ('0' + current.getHours()).slice(-2)
    const min = ('0' + current.getMinutes()).slice(-2)
    const sec = ('0' + current.getSeconds()).slice(-2)
    const mill = ('00' + current.getMilliseconds()).slice(-3)

    const backendDate = () => {
        const currentDate = `${year}-${month}-${day}T${hour}:${min}:${sec}.${mill}`
        return currentDate
    }

    const displayDate = () => {
        const currentDate = `${day}/${month} - ${hour}:${min}`
        return currentDate
    }

    //Render display date in readable format
    const ISOtoReadable = (date: string, showSeconds: boolean) => {
        const dateObject = new Date(date)
        const dateStr = dateObject.toLocaleDateString()
        const timeStr = dateObject.toLocaleTimeString()
        if (showSeconds) {
            return dateStr
        }
        return dateStr + ' ' + timeStr.slice(0, timeStr.length - 3)
    }

    // const getFeedTypes = async () => {
    //     let feedTypes:ITypes;
    //     if(typeof localStorage.getItem("feedTypes") == "string" ){
    //         feedTypes = JSON.parse(localStorage.getItem("feedTypes")!)
    //     } else {
    //         console.log("Ingen localStorage.");

    //         feedTypes = await getTypes()
    //     }
    //     return feedTypes
    // }

    const getFeedTypes = async () => {
        return await getTypes()
    }

    // Not working properly..
    // const sort = (toSort:any[],order:string, sortProperty?: any)=>{
    //     switch(order){
    //         case "asc":
    //             return toSort.sort((a,b) => a[sortProperty]-b[sortProperty])
    //         case "desc":
    //             return toSort.sort((a,b) => b[sortProperty] - a[sortProperty])
    //     }

    // }

    return {
        backendDate,
        displayDate,
        ISOtoReadable,
        getFeedTypes,
    }
})()
