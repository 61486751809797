import React, { FC, SyntheticEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap'
import LogOut, { logOutBtn } from '../shared/LogOut'
import '../../styles/MainNavigation.css'

const MainNavigation: FC = () => {
    const [showLogOut, setShowLogOut] = useState<boolean>(false)

    const navigate = useNavigate()

    const handleShowLogOut = (e: SyntheticEvent) => {
        e.preventDefault()
        if (!showLogOut) {
            setShowLogOut(true)
        }
    }

    return (
        <>
            <Navbar collapseOnSelect style={{ paddingTop: '0px' }}>
                <Container fluid id="main-nav-container">
                    <Navbar.Toggle />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link id="logo-to-home" to={`/`}>
                                <img
                                    alt={'no image :('}
                                    src={require('../../images/frid-logo.png')}
                                    height={70}
                                    width={70}
                                />
                                <div id="nav-header">Frid</div>
                            </Link>
                            <div id="menu-links">
                                <Link id="nav-link-home" to={'/'}>
                                    Home
                                </Link>
                                <Link id="nav-link-feed" to={'/feedcandidates'}>
                                    Archive
                                </Link>
                                <Link
                                    id="nav-link-new-feed"
                                    to={'/newfeedcandidate'}
                                >
                                    New
                                </Link>
                                <Link
                                    id="nav-link-statistic"
                                    to={'/statistics'}
                                >
                                    Statistics
                                </Link>
                            </div>
                        </Nav>
                        <Container fluid id="main-nav-center-container">
                            <Nav className="justify-content-center">
                                <div id="middle-div"></div>
                            </Nav>
                        </Container>
                    </Navbar.Collapse>
                    <Nav id="main-nav-right">
                        <NavDropdown
                            id="collasible-nav-dropdown-settings"
                            title={
                                <div>
                                    <img
                                        alt={'no image :('}
                                        src={require('../../images/settings.png')}
                                        height={40}
                                        width={40}
                                    />
                                </div>
                            }
                        >
                            <div id="dropdown-items-settings">
                                <NavDropdown.Item
                                    id="navLink"
                                    onClick={() =>
                                        alert(
                                            'No support for language change. :('
                                        )
                                    }
                                >
                                    Language
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    id="navLink"
                                    onClick={() => {
                                        navigate('/info')
                                    }}
                                >
                                    Info
                                </NavDropdown.Item>
                                <NavDropdown.Item id="navLink">
                                    <img
                                        alt={'no image :('}
                                        src={require('../../images/logout-icon.png')}
                                        height={30}
                                        width={30}
                                        onClick={handleShowLogOut}
                                    />
                                </NavDropdown.Item>
                            </div>
                        </NavDropdown>
                    </Nav>
                </Container>
            </Navbar>

            {showLogOut && (
                <LogOut logout={logOutBtn} back={() => setShowLogOut(false)} />
            )}
        </>
    )
}

export default MainNavigation
