import NewFeedCandidateLayout from '../../components/newFeedCandidates/NewFeedCandidateLayout'
import React from 'react'

const Article = () => {
    const form = {
        actionType: 'ARTICLE',
        url: true,
        quiz: false,
        calendar: false,
    }
    return <NewFeedCandidateLayout form={form} />
}

export default Article
