import NewFeedCandidateLayout from '../../components/newFeedCandidates/NewFeedCandidateLayout'
import React from 'react'

const Question = () => {
    const form = {
        actionType: 'QUIZ',
        url: false,
        quiz: true,
        calendar: false,
    }
    return <NewFeedCandidateLayout form={form} />
}

export default Question
