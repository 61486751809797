import FeedCandidateList from '../components/listFeedCandidates/FeedCandidateList'
import '../styles/FeedCandidates.css'
import React from 'react'

const FeedCandidates = () => {
    return (
        <div id="feed-container">
            <h1 id="feed-header">Feed Candidates</h1>
            <FeedCandidateList />
        </div>
    )
}

export default FeedCandidates
