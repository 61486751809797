import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingView = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}
        >
            <div>
                <span>Loading..</span>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </div>
    )
}

export default LoadingView
