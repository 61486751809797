import NewFeedCandidatesList from '../components/newFeedCandidates/main/NewFeedCandidateList'
import '../styles/NewFeedCandidate.css'
import React from 'react'

const NewFeedCandidate = () => {
    return (
        <div id="newfeed-container">
            <h1 id="newfeed-header">New Feed Candidate</h1>
            <div id="card-container">
                <NewFeedCandidatesList />
            </div>
        </div>
    )
}

export default NewFeedCandidate
