import React, { useEffect, useState } from 'react'
import {
    IFeedCandidate,
    ITypes,
    mainButtonState,
} from '../../interface/actionType'
import FeedCandidateItem from './FeedCandidateItem'
import { Button, Form } from 'react-bootstrap'
import TableView from './TableView'
import { supportFunctions } from '../shared/supportFunctions'
import StatisticItem from '../statistics/StatisticItem'
import { emptyCandidate } from '../../context/FeedCandidateContext'
import {
    getActiveFeedCandidates,
    getCreatedFeedCandidates,
    getHistoricFeedCandidates,
    makeHistoric,
    useActiveFeedCandidates,
    useCreatedFeedCandidates,
    useFeedLoading,
    useHistoricFeedCandidates,
} from '../../redux/feedSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'

export const FeedCandidateList = () => {
    const [modalVisible, setModalVisible] = useState(false)
    const [modalFCandidate, setModalFCandidate] =
        useState<IFeedCandidate>(emptyCandidate)

    const [filteredCandidates, setFilteredCandidates] = useState<
        IFeedCandidate[]
    >([])
    const [showTable, setShowTable] = useState<boolean>(true)

    //
    const [showCreated, setShowCreated] = useState<boolean>(true)
    const [showActive, setShowActive] = useState<boolean>(true)
    const [showHistoric, setShowHistoric] = useState<boolean>(true)
    const [editMode, setEditMode] = useState(false)

    const [newHistoric, setNewHistoric] = useState<string[]>([])

    const feedCreated = useCreatedFeedCandidates()
    const feedActive = useActiveFeedCandidates()
    const feedHistoric = useHistoricFeedCandidates()

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!feedCreated.length) {
            dispatch(getCreatedFeedCandidates())
        }
        if (!feedActive.length) {
            dispatch(getActiveFeedCandidates())
        }
        if (!feedHistoric.length) {
            dispatch(getHistoricFeedCandidates())
        }
    }, [dispatch, feedCreated, feedActive, feedHistoric])

    useEffect(() => {
        let _feedCandidates: IFeedCandidate[] = []

        if (showCreated) {
            _feedCandidates = _feedCandidates.concat(feedCreated)
        }
        if (showActive) {
            _feedCandidates = _feedCandidates.concat(feedActive)
        }
        if (showHistoric) {
            _feedCandidates = _feedCandidates.concat(feedHistoric)
        }

        const sorted = _feedCandidates.sort((firstItem, secondItem) =>
            secondItem.feedCandidateTime.localeCompare(
                firstItem.feedCandidateTime
            )
        )
        setFilteredCandidates(sorted)
    }, [
        showActive,
        showCreated,
        showHistoric,
        feedCreated,
        feedActive,
        feedHistoric,
    ])

    const toggleEditMode = () => {
        if (editMode) {
            setShowActive(true)
            setShowCreated(true)
            setShowHistoric(true)
            setEditMode(false)
        } else {
            setShowActive(true)
            setShowCreated(false)
            setShowHistoric(false)
            setEditMode(true)
        }
    }

    const postHistoric = () => {
        dispatch(
            makeHistoric({
                feedCandidates: newHistoric,
            })
        )
        setNewHistoric([])
        setEditMode(false)
    }
    const filterChecks = () => {
        return (
            <div id="filter-checks">
                <div id="showCreated-check">
                    <Form.Check
                        checked={showCreated === true}
                        className="checkbox"
                        id="important"
                        type={'checkbox'}
                        name={'showCreated'}
                        value={'showCreated'}
                        onChange={() => setShowCreated(!showCreated)}
                    />
                    <label id="showCreated-label">Created</label>
                </div>

                <div id="showActive-check">
                    <Form.Check
                        checked={showActive === true}
                        className="checkbox"
                        id="important"
                        type={'checkbox'}
                        name={'showActive'}
                        value={'showActive'}
                        onChange={() => setShowActive(!showActive)}
                    />
                    <label id="showActive-label">Active</label>
                </div>

                <div id="showHistoric-check">
                    <Form.Check
                        checked={showHistoric === true}
                        className="checkbox"
                        id="important"
                        type={'checkbox'}
                        name={'showHistoric'}
                        value={'showHistoric'}
                        onChange={() => setShowHistoric(!showHistoric)}
                    />
                    <label id="showHistoric-label">Historic</label>
                </div>
                <div id="full-count">
                    Currently showing: {filteredCandidates.length}
                </div>
                <div>
                    <Button onClick={() => setShowTable(!showTable)}>
                        {showTable ? 'Tile view' : 'Table view'}
                    </Button>
                </div>
                {showTable && (
                    <div>
                        <Button onClick={toggleEditMode}>
                            {editMode ? 'Cancel' : 'Edit'}
                        </Button>
                    </div>
                )}
                {editMode && (
                    <div>
                        <Button onClick={postHistoric}>Make historic</Button>
                    </div>
                )}
            </div>
        )
    }

    return (
        <>
            <div id="filter-container">
                {filterChecks()}
                <hr id="feed-candidate-line" />
                <div id="feed-tile-container">
                    {showTable ? (
                        <TableView
                            feedCandidates={filteredCandidates}
                            setNewHistoric={setNewHistoric}
                            newHistoric={newHistoric}
                            editMode={editMode}
                        />
                    ) : (
                        filteredCandidates.map((feedCandidate, key) => {
                            return (
                                <div id="feed-tile" key={key}>
                                    <FeedCandidateItem
                                        setModalFCandidate={setModalFCandidate}
                                        feedCandidate={feedCandidate}
                                        setModalVisible={setModalVisible}
                                    />
                                </div>
                            )
                        })
                    )}
                </div>
            </div>
            <StatisticItem
                feedCandidate={modalFCandidate}
                show={modalVisible}
                onHide={() => setModalVisible(false)}
            />
        </>
    )
}

export default FeedCandidateList
