import React, { FC, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { IFeedCandidate } from '../../interface/actionType'
import { supportFunctions } from '../shared/supportFunctions'
import { FeedCandidateContext } from '../../context/FeedCandidateContext'
import { FeedCandidateContextType } from '../../types/FeedCandidateContextType'
import '../../styles/TableView.css'
import { FormCheck } from 'react-bootstrap'

interface ITableView {
    feedCandidates: IFeedCandidate[]
    setNewHistoric: React.Dispatch<React.SetStateAction<string[]>>
    newHistoric: string[]
    editMode: boolean
}

const TableView: FC<ITableView> = ({
    feedCandidates,
    setNewHistoric,
    newHistoric,
    editMode,
}) => {
    const { addActiveFeedCandidate } = useContext(
        FeedCandidateContext
    ) as FeedCandidateContextType

    const navAround = useNavigate()
    let sorted = [...feedCandidates]
    sorted = sorted.sort((a, b) =>
        b.feedCandidateTime.localeCompare(a.feedCandidateTime)
    )
    if (feedCandidates.length === 0) {
        return <h3>There are none messages to show.</h3>
    }

    const calculateSimulationPercent = (feedCandidate: IFeedCandidate) => {
        const qualified =
            feedCandidate.feedCandidateSendStats?.numQualifiedReceiversFeed
        const total =
            feedCandidate.feedCandidateSendStats?.totalNumUsersSimulated
        if (qualified && total) {
            return (
                Math.ceil((parseInt(qualified) / parseInt(total)) * 100) + '%'
            )
        } else return 'Unknown'
    }

    const addToHistoric = (feedcandidate: string) => {
        if (newHistoric.includes(feedcandidate)) {
            setNewHistoric((prev) =>
                prev.filter((item) => item !== feedcandidate)
            )
        } else {
            setNewHistoric((prev) => [...prev, feedcandidate])
        }
    }

    const onPress = (feedCandidate: IFeedCandidate) => {
        if (editMode) {
            addToHistoric(feedCandidate.feedCandidateTime)
        } else {
            addActiveFeedCandidate(feedCandidate)
            navAround(
                `/newfeedcandidate/${feedCandidate.feedActionType.toLowerCase()}`
            )
        }
    }

    return (
        <table id="tableview">
            <tbody id="table-body">
                <tr id="table-top" className="tr-table">
                    <th>Time</th>
                    <th>Created By</th>
                    <th>Type</th>
                    <th>Content</th>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Receivers</th>
                </tr>

                {sorted.map((feedCandidate: IFeedCandidate, key: number) => {
                    function statusChange() {
                        if (feedCandidate.feedCandidateStatus === 'ACTIVE') {
                            return (
                                <td
                                    key={key}
                                    className="td-table"
                                    style={{ backgroundColor: '#9ad061' }}
                                >
                                    {feedCandidate.feedCandidateStatus}
                                </td>
                            )
                        } else if (
                            feedCandidate.feedCandidateStatus === 'CREATED'
                        ) {
                            return (
                                <td
                                    key={key}
                                    className="td-table"
                                    style={{ backgroundColor: '#ffc849' }}
                                >
                                    {feedCandidate.feedCandidateStatus}
                                </td>
                            )
                        } else if (
                            feedCandidate.feedCandidateStatus === 'HISTORIC'
                        ) {
                            return (
                                <td
                                    key={key}
                                    className="td-table"
                                    style={{ backgroundColor: '#24b6eb' }}
                                >
                                    {feedCandidate.feedCandidateStatus}
                                </td>
                            )
                        } else {
                            return (
                                <td
                                    key={key}
                                    style={{ backgroundColor: '#FFFF' }}
                                >
                                    {feedCandidate.feedCandidateStatus}
                                </td>
                            )
                        }
                    }
                    return (
                        <tr
                            className="tr-table"
                            key={key}
                            onClick={() => onPress(feedCandidate)}
                            style={
                                newHistoric.includes(
                                    feedCandidate.feedCandidateTime
                                )
                                    ? {
                                          color: '#f5f5f5',
                                          backgroundColor: '#24b6eb',
                                      }
                                    : {}
                            }
                        >
                            <td className="td-table" id="date-table">
                                {supportFunctions.ISOtoReadable(
                                    feedCandidate.feedCandidateTime!,
                                    false
                                )}
                            </td>
                            <td className="td-table" id="created-by-table">
                                {feedCandidate.createdBy}
                            </td>
                            <td className="td-table">
                                {feedCandidate.feedActionType}
                            </td>
                            <td className="td-table">
                                {feedCandidate.feedContentType}
                            </td>
                            <td className="td-table">{feedCandidate.title}</td>
                            {statusChange()}
                            <td className="td-table" id="recieved-table">
                                {calculateSimulationPercent(feedCandidate)}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default TableView
