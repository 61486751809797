/* eslint-disable prefer-const */
/* eslint-disable react/no-unescaped-entities */
import React, {
    ChangeEvent,
    FC,
    SyntheticEvent,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { Button, Form, FormGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FeedCandidateContext } from '../../context/FeedCandidateContext'
import {
    IAnswer,
    ICalendar,
    ICandidateAnswer,
    ICriterium,
    IFeedCandidate,
    feedContextValues,
} from '../../interface/actionType'
import { deleteFeedCandidate } from '../../services/BackendCalls'
import { FeedCandidateContextType } from '../../types/FeedCandidateContextType'
import Footer from './shared/Footer'
import SelectionCriteria from './shared/SelectionCriteria'
import '../../styles/NewFeedCandidateLayout.css'
import DateAndTime from './shared/DateAndTime'
import QuizComponent from './shared/QuizComponent'
import Summary from './Summary'
import Prompt from './Prompt'
import dayjs from 'dayjs'
import SubstituteSelector from './SubstituteSelector'
import {
    addFeedCandidate,
    getFeedTypes,
    useFeedTypes,
} from '../../redux/feedSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import DateTimePicker from 'react-datetime-picker'

interface INewFeedCandidateInformation {
    form: {
        actionType: string
        url: boolean
        quiz: boolean
        calendar: boolean
    }
}

const NewFeedCandidateLayout: FC<INewFeedCandidateInformation> = ({ form }) => {
    const { savedFeedCandidate, removeActiveFeedCandidate } = useContext(
        FeedCandidateContext
    ) as FeedCandidateContextType

    const feedTypes = useFeedTypes()

    const dispatch = useDispatch<AppDispatch>()

    const layout = document.getElementById('layout-container') as HTMLElement
    const visibilityFooter = document.getElementById(
        'footer-component'
    ) as HTMLElement
    const visibilityHeader = document.getElementById(
        'header-follow'
    ) as HTMLElement
    const [criteriumList, setCriteriumList] = useState<ICriterium[]>([])

    const [candidateAnswer, setCandidateAnswer] = useState<ICandidateAnswer>({
        profileDataType: undefined,
        answerType: undefined,
        answerOptions: [],
    })

    const pushTypes = ['FEED_ONLY', 'PUSH_ONLY', 'FEED_AND_PUSH']
    const [important, setImportant] = useState(false)
    const [thumbs, setThumbs] = useState(false)
    const [feedback, setFeedback] = useState(false)
    const [isQuiz, setIsQuiz] = useState<'QUIZ' | 'PROFILING' | 'QUESTION'>(
        'QUIZ'
    )
    const [validFeedCandidate, setValidFeedCandidate] = useState(false)
    const [showSummary, setShowSummary] = useState(false)
    const [showTemplate, setShowTemplate] = useState(false)
    const [showSaved, setShowSaved] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [inputStatus, setInputStatus] = useState(false)
    const [feedCandidate, setFeedCandidate] = useState<IFeedCandidate>({
        feedActionType: '',
        title: '',
        text: '',
        feedContentType: '',
        target: '',
        selectionCriteriaList: [],
        feedCandidateTime: dayjs().toISOString().slice(0, -1),
        feedCandidateStatus: 'CREATED',
        feedCandidateImportance: 'NORMAL',
        candidateChannel: 'FEED_ONLY',
        candidateAnswer: [],
        feedCandidateSendStats: {
            feedCandidateTime: undefined,
            numQualifiedReceiversFeed: undefined,
            numQualifiedReceiversPush: undefined,
            numQualifiedReceiversPushNoPushToken: undefined,
            totalNumUsersSimulated: undefined,
        },
        feedContextList: [],
        plannedPublishTime: '',
    })

    const [calendarItem, setCalendarItem] = useState<ICalendar>({
        title: '',
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        location: '',
        allDay: false,
    })

    const onLaunchFunctions = () => {
        const populateCandidateAnswer = (
            _candidateAnswer: ICandidateAnswer[]
        ) => {
            const answer = _candidateAnswer.findIndex((answer) => {
                return (
                    answer.answerType !== 'THUMBS' &&
                    answer.answerType !== 'FEEDBACK'
                )
            })

            setCandidateAnswer(_candidateAnswer[answer])

            setThumbs(
                _candidateAnswer.findIndex((answer) => {
                    return answer.answerType === 'THUMBS'
                }) !== -1
                    ? true
                    : false
            )

            setFeedback(
                _candidateAnswer.findIndex((answer) => {
                    return answer.answerType === 'FEEDBACK'
                }) !== -1
                    ? true
                    : false
            )
        }

        if (savedFeedCandidate.feedCandidateTime !== '') {
            setFeedCandidate(savedFeedCandidate)
            if (savedFeedCandidate.selectionCriteriaList) {
                setCriteriumList(savedFeedCandidate.selectionCriteriaList)
                if (
                    savedFeedCandidate.candidateAnswer &&
                    savedFeedCandidate.candidateAnswer.length > 0
                ) {
                    populateCandidateAnswer(savedFeedCandidate.candidateAnswer)
                }
            }

            if (savedFeedCandidate.feedCandidateStatus != 'CREATED') {
                setInputStatus(true)
            }
            if (savedFeedCandidate.feedActionType === 'CALENDAR_ADD') {
                setCalendarItem(JSON.parse(savedFeedCandidate.target))
            }
            removeActiveFeedCandidate()
        } else {
            setFeedCandidate({
                ...feedCandidate,
                feedActionType: form.actionType,
            })
        }
    }

    const checkType = (type: string) => {
        return type === 'INFO' || type === 'QUIZ' || type === 'QUESTION'
    }

    useEffect(() => {
        let _feedCandidate = { ...feedCandidate }
        const values = Object.values(_feedCandidate)

        if (!values.includes('')) {
            setValidFeedCandidate(true)
        } else {
            if (values.some(checkType)) {
                if (values.some((value) => value === 'QUIZ')) {
                    delete _feedCandidate.text
                }
                delete _feedCandidate.target
                if (!Object.values(_feedCandidate).includes('')) {
                    return setValidFeedCandidate(true)
                }
            }
            setValidFeedCandidate(false)
        }
    }, [feedCandidate])

    useEffect(() => {
        setFeedCandidate({
            ...feedCandidate,
            selectionCriteriaList: criteriumList,
        })
    }, [criteriumList])

    useEffect(() => {
        addCandidateAnswer()
    }, [candidateAnswer])

    const contentType = useMemo(() => {
        if (!feedTypes) return ['Loading..']
        return ['---Select type---', feedTypes.feedContentTypes].flat()
    }, [feedTypes])

    useEffect(() => {
        setFeedCandidate({
            ...feedCandidate,
            target: JSON.stringify(calendarItem),
        })
    }, [calendarItem])

    useEffect(() => {
        onLaunchFunctions()
        dispatch(getFeedTypes())
    }, [])

    const addSelection = (criterium: ICriterium) => {
        setCriteriumList([...criteriumList, criterium])
    }

    const deleteSelectionCriterium = (index: number) => {
        setCriteriumList(
            criteriumList.filter(
                (item) => criteriumList.indexOf(item) !== index
            )
        )
    }

    const deleteAnswerOption = (answerOption: IAnswer) => {
        let _candidateAnswer = { ...candidateAnswer }

        if (candidateAnswer.answerOptions) {
            _candidateAnswer.answerOptions =
                candidateAnswer.answerOptions.filter(
                    (item) => item !== answerOption
                )
            setCandidateAnswer(_candidateAnswer)
        }
    }

    useEffect(() => {
        if (
            feedCandidate.feedActionType === 'QUIZ' ||
            feedCandidate.feedActionType === 'QUESTION'
        ) {
            setFeedCandidate({
                ...feedCandidate,
                title: '',
                text: '',
                feedContentType: isQuiz === 'PROFILING' ? 'PROFILING' : '',
                feedActionType: isQuiz === 'QUIZ' ? 'QUIZ' : 'QUESTION',
            })
        }
    }, [isQuiz])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.currentTarget
        const { name } = target
        const { value } = target

        setFeedCandidate({ ...feedCandidate, [name]: value })

        if (form.calendar) {
            setCalendarItem({ ...calendarItem, [name]: value })
        }
    }
    const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
        setFeedCandidate({ ...feedCandidate, feedContentType: e.target.value })
    }

    const handleTemplate = () => {
        setShowTemplate(true)
        setFeedCandidate({
            ...feedCandidate,
            feedCandidateTime: dayjs().toISOString().slice(0, -1),
            feedCandidateStatus: 'CREATED',
        })

        setInputStatus(false)
        setTimeout(() => {
            setShowTemplate(false)
        }, 2000)
    }

    const handleSelectCriteria = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFeedCandidate({
            ...feedCandidate,
            candidateChannel: e.target.value,
        })
    }

    const removeStyle = () => {
        if (showSummary) {
            layout.classList.remove('blurr-container')
            visibilityFooter.classList.remove('visibility-hidden')
            setShowSummary(false)
            setShowDelete(false)
        }
    }

    const checkUri = () => {
        if (feedCandidate.target.includes('https://www.')) {
            return <p style={{ color: 'green' }}>Gyldig url</p>
        } else {
            return <p>ugyldig</p>
        }
    }

    //Adds candidate answer to active feedcandidate
    const addCandidateAnswer = () => {
        let _candidateAnswer: ICandidateAnswer[] = []
        const { profileDataType, answerType } = candidateAnswer
        if (!!profileDataType || !!answerType) {
            _candidateAnswer.push(candidateAnswer)
            if (thumbs) {
                _candidateAnswer.push({
                    profileDataType: undefined,
                    answerType: 'THUMBS',
                    answerOptions: [],
                })
            }
            if (feedback) {
                _candidateAnswer.push({
                    profileDataType: undefined,
                    answerType: 'FEEDBACK',
                    answerOptions: [],
                })
            }
        }
        setFeedCandidate({
            ...feedCandidate,
            candidateAnswer: _candidateAnswer,
        })
    }

    //Save feedCandidate
    const handleSavedStatus = () => {
        setShowSaved(true)
        dispatch(
            addFeedCandidate({
                feedCandidate: feedCandidate,
                status: 'CREATED',
            })
        )
        removeActiveFeedCandidate()
        setTimeout(() => {
            setShowSaved(false)
        }, 2000)
    }

    //Send feedCandidate
    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault()
        removeActiveFeedCandidate()

        if (!showSummary) {
            layout.classList.add('blurr-container')
            visibilityFooter.classList.add('visibility-hidden')
            // visibilityHeader.classList.add('visibility-hidden')
            setShowSummary(true)
        } else {
            removeStyle()
        }
    }

    //important checkbox
    useEffect(() => {
        const _important =
            feedCandidate.feedCandidateImportance === 'IMPORTANT' ? true : false
        setImportant(_important)
    }, [])

    const handleSetImportant = () => {
        const value = () => {
            return important ? 'NORMAL' : 'IMPORTANT'
        }
        setFeedCandidate({ ...feedCandidate, feedCandidateImportance: value() })
        setImportant(!important)
    }

    //Delete component
    const handleDelete = () => {
        deleteFeedCandidate(feedCandidate.feedCandidateTime!)
    }
    const DeleteItem = () => {
        return (
            <div id="delete-container">
                <h1 id="delete-header">Are you sure you want to delete?</h1>
                <p id="delete-text">It will be gone forever...</p>
                <Button id="delete-back-btn" onClick={removeStyle}>
                    Back
                </Button>
                <Link id="delete-link" to={'/'}>
                    <Button id="delete-btn" onClick={handleDelete}>
                        Delete
                    </Button>
                </Link>
            </div>
        )
    }

    const addSubstitute = (substitute: string, target: 'title' | 'text') => {
        setFeedCandidate({
            ...feedCandidate,
            [target]: feedCandidate[target] + substitute,
        })
    }

    const handleContextValue = (type: feedContextValues) => {
        setFeedCandidate({
            ...feedCandidate,
            feedContextList: feedCandidate.feedContextList.includes(type)
                ? feedCandidate.feedContextList.filter(
                      (value) => value !== type
                  )
                : [...feedCandidate.feedContextList, type],
        })
    }

    return (
        <>
            <div id="show-summary">
                {showSummary && (
                    <Summary
                        feedCandidate={feedCandidate}
                        setFeedCandidate={setFeedCandidate}
                    />
                )}
            </div>
            <div id="show-template">
                {showTemplate && <Prompt message={'Copied!'} />}
            </div>
            <div id="show-saved">
                {showSaved && <Prompt message={'Saved!'} />}
            </div>
            <div id="show-delete">{showDelete && <DeleteItem />}</div>
            <div id="layout-container">
                <div id="remove-style" onClick={removeStyle}>
                    <div id="layout-header">
                        <h1>{feedCandidate.feedActionType}</h1>
                    </div>

                    <div id="layout-tiles">
                        <div id="layout-forms">
                            {form.quiz && (
                                <div id="change-component">
                                    <div className="flexRow">
                                        <input
                                            id="radio-change"
                                            type="radio"
                                            name="radio"
                                            value={'QUIZ'}
                                            checked={isQuiz === 'QUIZ'}
                                            onChange={() => setIsQuiz('QUIZ')}
                                            disabled={inputStatus}
                                        />
                                        <label id="quiz-label">Quiz</label>
                                    </div>
                                    <div className="flexRow">
                                        <input
                                            id="radio-change"
                                            type="radio"
                                            name="radio"
                                            value={'QUESTION'}
                                            checked={isQuiz === 'QUESTION'}
                                            onChange={() =>
                                                setIsQuiz('QUESTION')
                                            }
                                            disabled={inputStatus}
                                        />
                                        <label id="quiz-label">Question</label>
                                    </div>
                                    <div className="flexRow">
                                        <input
                                            id="radio-change"
                                            type="radio"
                                            name="radio"
                                            value="PROFILING"
                                            checked={isQuiz === 'PROFILING'}
                                            onChange={() =>
                                                setIsQuiz('PROFILING')
                                            }
                                            disabled={inputStatus}
                                        />
                                        <label id="question-label">
                                            Profiling
                                        </label>
                                    </div>
                                </div>
                            )}
                            <Form
                                onSubmit={handleSubmit}
                                id="layout-form-container"
                            >
                                <FormGroup id="layout-header-form">
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Form.Label>
                                            {!form.quiz ? 'Header' : 'Question'}
                                        </Form.Label>
                                        <SubstituteSelector
                                            onPress={addSubstitute}
                                            target={'title'}
                                        />
                                    </div>

                                    <Form.Control
                                        type="text"
                                        placeholder={
                                            !form.quiz
                                                ? 'Enter title'
                                                : 'Enter question'
                                        }
                                        name="title"
                                        value={feedCandidate.title}
                                        onChange={handleChange}
                                        disabled={inputStatus}
                                    />
                                </FormGroup>

                                <FormGroup id="layout-text-form">
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Form.Label>Text</Form.Label>
                                        <SubstituteSelector
                                            onPress={addSubstitute}
                                            target={'text'}
                                        />
                                    </div>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={8}
                                        placeholder="Enter text"
                                        name="text"
                                        value={feedCandidate.text}
                                        onChange={handleChange}
                                        disabled={inputStatus}
                                    />
                                </FormGroup>

                                {form.url && (
                                    <FormGroup id="layout-url-form">
                                        <Form.Label>URL</Form.Label>
                                        <Form.Control
                                            type="url"
                                            placeholder="Add URL"
                                            name="target"
                                            value={feedCandidate.target}
                                            onChange={handleChange}
                                            disabled={inputStatus}
                                        />
                                        <Form.Label>{checkUri()}</Form.Label>
                                    </FormGroup>
                                )}
                                <div id="important-change-component">
                                    <div className="flexRow">
                                        <input
                                            id="radio-change"
                                            type="radio"
                                            name="radio"
                                            checked={important === false}
                                            onChange={() =>
                                                setImportant(!important)
                                            }
                                            disabled={inputStatus}
                                        />
                                        <label>Normal</label>
                                    </div>
                                    <div className="flexRow">
                                        <input
                                            id="radio-change"
                                            type="radio"
                                            name="radio"
                                            checked={important === true}
                                            onChange={handleSetImportant}
                                            disabled={inputStatus}
                                        />
                                        <label>Important</label>
                                    </div>
                                </div>
                                <FormGroup id="layout-content-form">
                                    <Form.Label>Content-type</Form.Label>
                                    <Form.Select
                                        value={
                                            feedCandidate.feedContentType !== ''
                                                ? feedCandidate.feedContentType
                                                : '---Content Type---'
                                        }
                                        onChange={handleSelect}
                                        disabled={inputStatus}
                                    >
                                        {contentType.map(
                                            (type: string, key: number) => {
                                                return (
                                                    <option
                                                        key={key}
                                                        value={type}
                                                    >
                                                        {type}
                                                    </option>
                                                )
                                            }
                                        )}
                                    </Form.Select>
                                    <div id="check-push-component">
                                        <Form.Label>Channel</Form.Label>
                                        <Form.Select
                                            className="feedpush-form"
                                            name="criteriaCode"
                                            onChange={handleSelectCriteria}
                                            disabled={inputStatus}
                                            value={
                                                feedCandidate.candidateChannel !==
                                                ''
                                                    ? feedCandidate.candidateChannel
                                                    : '---Content Type---'
                                            }
                                        >
                                            {pushTypes.map(
                                                (type: string, key: number) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={type}
                                                        >
                                                            {type}
                                                        </option>
                                                    )
                                                }
                                            )}
                                        </Form.Select>
                                    </div>
                                </FormGroup>
                                <div className="flex-row">
                                    <div>
                                        <input
                                            type="checkbox"
                                            size={20}
                                            onClick={() =>
                                                handleContextValue('FRONTPAGE')
                                            }
                                        />
                                        <label>Frontpage</label>
                                    </div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            size={20}
                                            onClick={() =>
                                                handleContextValue('BUSINESS')
                                            }
                                        />
                                        <label>Business</label>
                                    </div>
                                </div>
                                <div>
                                    <DateTimePicker
                                        value={
                                            feedCandidate.plannedPublishTime !==
                                            ''
                                                ? dayjs(
                                                      feedCandidate.plannedPublishTime
                                                  ).toDate()
                                                : undefined
                                        }
                                        onChange={(value) =>
                                            setFeedCandidate({
                                                ...feedCandidate,
                                                plannedPublishTime:
                                                    value.toISOString(),
                                            })
                                        }
                                    />
                                </div>
                            </Form>
                        </div>
                        <div id="selection-criteria-component">
                            <SelectionCriteria
                                deleteCriterium={deleteSelectionCriterium}
                                criteriumList={criteriumList}
                                addSelection={addSelection}
                                feedActionType={feedCandidate.feedActionType}
                            />
                        </div>
                        {form.quiz && (
                            <div id="quiz-question-component">
                                <QuizComponent
                                    setCandidateAnswer={setCandidateAnswer}
                                    setFeedCandidate={setFeedCandidate}
                                    feedCandidate={feedCandidate}
                                    candidateAnswer={candidateAnswer}
                                    thumbs={thumbs}
                                    setThumbs={setThumbs}
                                    feedback={feedback}
                                    setFeedback={setFeedback}
                                    isQuiz={isQuiz}
                                    deleteAnswer={deleteAnswerOption}
                                />
                            </div>
                        )}
                        {form.calendar && (
                            <div id="calendar-component">
                                <DateAndTime
                                    calendarItem={calendarItem}
                                    setCalendarItem={setCalendarItem}
                                />
                            </div>
                        )}
                        <div id="known-bugs">
                            <h2>Kjente feil:</h2>
                            <ul>
                                <li>
                                    Når du endrer et selection criteria er vil
                                    ikke det nederste feltet resette seg
                                </li>

                                <li>
                                    Hvis du vil sjekke at den er sendt, gå til
                                    archive, den skal ligge da ligge øverst i
                                    listen
                                </li>
                                <li>
                                    På quiz er det ikke støtte i appen enda for
                                    "thumbs" og "feedback"
                                </li>
                                <li>
                                    Hvis du vil lagre en quiz må det være{'  '}
                                    <u>minst ett</u> riktig svar.
                                </li>
                                <li>
                                    Skal du sende Question bestemmer du om det
                                    multiple choice eller ett svar med å sette
                                    antall riktige til 1 eller flere.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="footer-component">
                    <Footer
                        deleteStatusFromFooter={() => setShowDelete(true)}
                        savedStatusFromFooter={handleSavedStatus}
                        createTemplate={handleTemplate}
                        handleSubmit={handleSubmit}
                        disabledSendSaveBtn={!validFeedCandidate}
                        feedCandidate={feedCandidate}
                    />
                </div>
            </div>
        </>
    )
}

export default NewFeedCandidateLayout
