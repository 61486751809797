/* eslint-disable prefer-const */
import React, {
    FC,
    useEffect,
    useState,
    KeyboardEvent,
    useContext,
} from 'react'
import { Button, Form, FormGroup } from 'react-bootstrap'
import { FeedTypesContext } from '../../../context/FeedTypesContext'
import {
    IAnswer,
    ICandidateAnswer,
    IFeedCandidate,
    IProfilingQuestion,
    ITypes,
} from '../../../interface/actionType'
import { getProfilingAnswers } from '../../../services/BackendCalls'
import '../../../styles/QuizComponent.css'
import { FeedTypesContextType } from '../../../types/FeedTypesContextType'

interface IQuizComponent {
    candidateAnswer: ICandidateAnswer
    setCandidateAnswer: (candidateAnswer: ICandidateAnswer) => void
    setFeedCandidate: (feedCandidate: IFeedCandidate) => void
    feedCandidate: IFeedCandidate
    isQuiz: string
    thumbs: boolean
    feedback: boolean
    setThumbs: (value: boolean) => void
    setFeedback: (value: boolean) => void
    deleteAnswer: (answer: IAnswer) => void
}

const QuizComponent: FC<IQuizComponent> = ({
    candidateAnswer,
    setCandidateAnswer,
    setFeedCandidate,
    feedCandidate,
    isQuiz,
    thumbs,
    feedback,
    setThumbs,
    setFeedback,
    deleteAnswer,
}) => {
    const [profileDataType, setProfileDataType] = useState<string[]>([])
    const [profilingQuestion, setProfilingQuestions] =
        useState<IProfilingQuestion>()

    const [profilingAnswers, setProfilingAnswers] = useState<
        IProfilingQuestion[]
    >([])
    const [answer, setAnswer] = useState<string>('')
    const [isAnswerText, setIsAnswerText] = useState<boolean>(false)
    const { feedTypes } = useContext(FeedTypesContext) as FeedTypesContextType

    const getContent = async () => {
        // let feedTypes: ITypes = await supportFunctions.getFeedTypes()

        const content: string[] = [...feedTypes.profileDataTypes].map(
            (dataTypes) => {
                return dataTypes.profileDataType
            }
        )
        content.unshift('---Content Type---')
        setProfileDataType(content)
    }

    const getProfileQuestion = async () => {
        if (profilingAnswers.length <= 0) {
            const res = await getProfilingAnswers()
            if (res) {
                setProfilingAnswers(res)
            }
        }
    }

    useEffect(() => {
        getProfileQuestion()
        getContent()
    }, [])

    useEffect(() => {
        setCandidateAnswer({
            profileDataType: undefined,
            answerType: undefined,
            answerOptions: [],
        })
    }, [isQuiz])

    useEffect(() => {
        if (isAnswerText === true && candidateAnswer.answerOptions) {
            const _answerOptions = [...candidateAnswer.answerOptions]

            _answerOptions.map((answer) => (answer.correct = isAnswerText))
            setCandidateAnswer({
                ...candidateAnswer,
                answerOptions: _answerOptions,
                answerType: 'TEXT',
            })
        } else {
            setCandidateAnswer({
                ...candidateAnswer,
                answerType: checkAnswerType(),
            })
        }
    }, [isAnswerText])

    const addAnswer = () => {
        let _answers = candidateAnswer.answerOptions
        if (
            _answers?.some(
                (_answer) =>
                    _answer.answer.toLocaleLowerCase() ===
                    answer.toLocaleLowerCase()
            )
        ) {
            alert('You cant add duplicated answers..')
            setAnswer('')
        } else {
            _answers?.push({
                answer: answer,
                correct: isAnswerText ? true : false,
            })

            setCandidateAnswer({ ...candidateAnswer, answerOptions: _answers })
            setAnswer('')
        }
    }

    const handleChange = (answer: string) => {
        let _answers = candidateAnswer.answerOptions
        if (_answers) {
            for (let i = 0; i < _answers.length; i++) {
                if (_answers[i].answer === answer) {
                    _answers[i].correct = !_answers[i].correct
                }
            }
            const answerType = checkAnswerType()

            setCandidateAnswer({
                ...candidateAnswer,
                answerOptions: _answers,
                answerType: answerType,
            })
        }
    }

    const checkAnswerType = (): string => {
        let numOfCorrect = 0
        if (candidateAnswer.answerOptions) {
            numOfCorrect = candidateAnswer.answerOptions.filter(
                (answer) => !!answer.correct
            ).length
        }
        if (numOfCorrect === 0) return ''
        return numOfCorrect > 1 ? 'CHOOSE_MANY' : 'CHOOSE_ONE'
    }

    const renderProfileQuestion = (question: string) => {
        setCandidateAnswer({
            ...candidateAnswer,
            profileDataType: question,
        })
        const profilingQuestion = profilingAnswers.find(
            (profile) => profile.profileDataType === question
        )
        if (profilingQuestion) {
            setFeedCandidate({
                ...feedCandidate,
                title: profilingQuestion.profileDataHeadline,
                text: 'Dette spørsmålet er med på å bygge profilen din slik at vi kan gi bedre råd',
            })
            setProfilingQuestions(profilingQuestion)
        }
    }

    const showProfiling = () => {
        if (profilingQuestion?.candidateAnswer) {
            const tmp = profilingQuestion.candidateAnswer
            return (
                <>
                    <h3>Answer options for profiling questions</h3>
                    <ul>
                        {tmp?.answerOptions?.map(
                            (option: IAnswer, key: number) => {
                                return <li key={key}>{option.answer}</li>
                            }
                        )}
                    </ul>
                </>
            )
        }
    }

    const showAnswer = () => {
        return (
            <div id="quiz-top">
                <table id="quiz-tableview">
                    <tbody>
                        {candidateAnswer.answerOptions!.map(
                            (answer: IAnswer, key: number) => {
                                return (
                                    <tr key={key}>
                                        <td className="quiz-td">
                                            {answer.correct ? (
                                                <p
                                                    id="color-answer"
                                                    style={{ color: '#9ad061' }}
                                                >
                                                    {answer.answer}
                                                </p>
                                            ) : (
                                                <p
                                                    id="color-answer"
                                                    style={{ color: '#ef0119' }}
                                                >
                                                    {answer.answer}
                                                </p>
                                            )}
                                        </td>
                                        <td className="quiz-td" id="check-x">
                                            {!isAnswerText && (
                                                <Form.Check
                                                    className="checkbox"
                                                    type={'checkbox'}
                                                    id={'important'}
                                                    name={answer.answer}
                                                    checked={answer.correct}
                                                    value={'important'}
                                                    onChange={() =>
                                                        handleChange(
                                                            answer.answer
                                                        )
                                                    }
                                                />
                                            )}
                                            <Button
                                                id="delete-answer-btn"
                                                onClick={() =>
                                                    deleteAnswer(answer)
                                                }
                                            >
                                                X
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>
            </div>
        )
    }

    const createQuestion = () => {
        return (
            <>
                <FormGroup id="question-content-form">
                    <Form.Select
                        id="question-form"
                        name="operator"
                        onChange={(e) => renderProfileQuestion(e.target.value)}
                    >
                        {profileDataType.map((type: string, key: number) => {
                            return (
                                <option key={key} value={type}>
                                    {type}
                                </option>
                            )
                        })}
                    </Form.Select>
                </FormGroup>
                <div>{showProfiling()}</div>
            </>
        )
    }
    const quizRadio = () => {
        return (
            <>
                <hr id="quiz-line" />
                <div id="quiz-radio-container">
                    <div id="change-text">
                        <input
                            id="text-change"
                            type="radio"
                            name="alternatives-change"
                            value={'Text'}
                            checked={isAnswerText === true}
                            onChange={() =>
                                setIsAnswerText((prevState) => !prevState)
                            }
                        />
                        <label id="text-label">Text</label>
                    </div>
                    <div id="change-alt">
                        <input
                            id="alternative-change"
                            type="radio"
                            name="alternatives-change"
                            value={'Alternatives'}
                            checked={!isAnswerText === true}
                            onChange={() =>
                                setIsAnswerText((prevState) => !prevState)
                            }
                        />
                        <label id="alt-label">Alternatives</label>
                    </div>
                </div>
            </>
        )
    }

    const createQuiz = () => {
        if (profilingQuestion !== undefined) {
            setProfilingQuestions(undefined)
        }

        return (
            <div id="quiz-middle">
                <input
                    id="quiz-answer-input"
                    value={answer}
                    name="answer"
                    type={'text'}
                    placeholder="Enter answer here"
                    onChange={(e) => {
                        setAnswer(e.target.value)
                    }}
                    onKeyPress={(e: KeyboardEvent) =>
                        e.key === 'Enter' && addAnswer()
                    }
                />
                <Button
                    disabled={answer === '' ? true : false}
                    onClick={addAnswer}
                    id="quiz-answer-btn"
                >
                    Add
                </Button>
            </div>
        )
    }

    return (
        <div id="quiz-component-container">
            {isQuiz !== 'PROFILING' && quizRadio()}
            {showAnswer()}
            {isQuiz === 'PROFILING' && createQuestion()}
            {isQuiz !== 'PROFILING' && createQuiz()}
            <hr id="quiz-line" />
            <div id="quiz-bottom">
                <div id="quiz-feedback">
                    <Form.Check
                        className="checkbox"
                        type={'checkbox'}
                        id={'important'}
                        name={'feedback'}
                        value={'important'}
                        onChange={() => setFeedback(!feedback)}
                        disabled={true}
                    />
                    <label id="feedback-label">Feedback (disabled)</label>
                </div>
                <div id="quiz-thumbs">
                    <Form.Check
                        className="checkbox"
                        type={'checkbox'}
                        id={'important'}
                        name={'thumbs'}
                        value={'important'}
                        onChange={() => setThumbs(!thumbs)}
                        disabled={true}
                    />
                    <label id="thumbs-label">Thumbs (disabled)</label>
                </div>
            </div>
        </div>
    )
}

export default QuizComponent
