import '../styles/Statistics.css'
import React from 'react'

const Statistics = () => {
    return (
        <div id="stat-container">
            <h1 id="stat-header">Statistics</h1>
            <div id="stat-login" className="stat-boxes">
                Logged in last week
            </div>
            <div id="stat-sent" className="stat-boxes">
                Sent xx feed admins last 7 days
            </div>
        </div>
    )
}

export default Statistics
