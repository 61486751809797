import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { IActionType } from '../../../interface/actionType'

const NewFeedCandidateItem: FC<IActionType> = ({ name }) => {
    const cardTitle = (name: string) => {
        if (name === 'Question') {
            return <h2>Quiz & Profiling</h2>
        }
        if (name === 'Calendar_add') {
            return <h2>Calendar</h2>
        }
        if (name === 'Uri_link') {
            return <h2>URI-Link</h2>
        } else {
            return <h2>{name}</h2>
        }
    }

    return (
        <>
            <Link id="card-link" to={`/newfeedcandidate/${name}`}>
                <div id="card-name">{cardTitle(name)}</div>
            </Link>
        </>
    )
}

export default NewFeedCandidateItem
