import NewFeedCandidateLayout from '../../components/newFeedCandidates/NewFeedCandidateLayout'
import React from 'react'

const Info = () => {
    const form = {
        actionType: 'INFO',
        url: false,
        quiz: false,
        calendar: false,
    }
    return <NewFeedCandidateLayout form={form} />
}

export default Info
