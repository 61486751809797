import React, { FC, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/esm/Form'
import {
    getEmail,
    getProfilingAnswers,
    getUserData,
    updateUserData,
} from '../../../services/BackendCalls'
import {
    ICriterium,
    ICriteriumSelection,
    IFeedAdminData,
    IProfilingQuestion,
} from '../../../interface/actionType'
import CriteriumItem from './CriteriumItem'
import '../../../styles/SelectionCriteria.css'
import Calendar from 'react-calendar'
import { getFeedTypes, useFeedTypes } from '../../../redux/feedSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../redux/store'

interface ISelectionFunction {
    addSelection: (criterium: ICriterium) => void
    criteriumList: ICriterium[]
    deleteCriterium: (index: number) => void
    feedActionType: string
}

const SelectionCriteria: FC<ISelectionFunction> = ({
    addSelection,
    criteriumList,
    deleteCriterium,
    feedActionType,
}) => {
    const [criteriaCodes, setCriteriaCodes] = useState<string[]>([])
    const [criteriaOperators, setCriteriaOperators] = useState<string[]>([])

    const [favCodes, setFavCodes] = useState<string[]>([])
    const [sendAll, setSendAll] = useState<boolean>(false)
    const [showAll, setShowAll] = useState<boolean>(false)
    const [newCriterium, setNewCriterium] = useState<ICriterium>({
        criteriaCode: '',
        operator: '',
        operand: '',
    })
    const [CriteriumList, setCriteriumList] = useState<ICriteriumSelection[]>(
        []
    )

    const feedTypes = useFeedTypes()
    const dispatch = useDispatch<AppDispatch>()

    const [fullProfilingAnswers, setFullProfilingAnswers] = useState<
        IProfilingQuestion[]
    >([])
    const [profilingAnswers, setProfilingAnswers] = useState<string[]>([])
    const [userData, setUserData] = useState<IFeedAdminData>({
        favouriteSelection: [],
        lastLoggedIn: '',
    })

    const getCriteriaCodes = async () => {
        if (showAll) {
            if (favCodes === undefined || favCodes.length === 0) {
                const tmp: IFeedAdminData = await getUserData()

                if (tmp.favouriteSelection[0] === undefined) {
                    setShowAll(!showAll)
                    getCriteriaCodes()
                } else {
                    setUserData(tmp)
                    setFavCodes(tmp.favouriteSelection)
                }
            }
            setCriteriaCodes(favCodes)
        } else {
            if (!feedTypes) return
            setCriteriumList(feedTypes.criteriaCodes)
            const mappedContent: string[] = feedTypes.criteriaCodes.map(
                (content: { description: string }) => content.description
            )
            //sorts by alphabet :)
            mappedContent.sort(function (a, b) {
                if (a < b) {
                    return -1
                }
                if (a > b) {
                    return 1
                }
                return 0
            })
            setCriteriaCodes(mappedContent)
        }
    }

    const checkCriteriaGroup = (choice: string) => {
        CriteriumList.forEach((crit) => {
            if (crit.description === choice) {
                if (crit.criteriaCode.includes('PROFILE_')) {
                    choice = crit.criteriaCode.slice(8)
                }
            }
        })
        console.log(choice)

        switch (choice) {
            case 'Main email address':
            case 'Subscription type':
            case 'Registered company domain':
            case 'The health check colour':
            case 'Negative impact at work':
            case 'Financially worried':
            case 'Language':
                getCriteriaOperators('strings')
                break
            case 'Number of budgets':
            case 'Number of banks':
            case 'Number of account collections':
            case 'Number of accounts':
            case 'Last logged in (date)':
                getCriteriaOperators('Integers')
                break
            default:
                getCriteriaOperators('Profiling', choice)
                break
        }
    }

    const getCriteriaOperators = async (group: string, choice?: string) => {
        if (!feedTypes) return
        setProfilingAnswers([])
        if (fullProfilingAnswers.length < 1) {
            const profilingAnswers = await getProfilingAnswers()
            setFullProfilingAnswers(profilingAnswers)
        }
        let content: string[] = []
        console.log(group)

        switch (group) {
            case 'datetime':
            case 'Integers':
                content = ['LESS_THAN', 'EQUALS', 'GREATER_THAN']
                break
            case 'Profiling':
                content = ['ARRAY_CONTAINS', 'ARRAY_CONTAINS_OR_NO_DATA']
                fullProfilingAnswers.forEach((element: any) => {
                    if (element.profileDataType === choice) {
                        const tmp: any[] = []
                        element.candidateAnswer.answerOptions.forEach(
                            (el: any) => {
                                tmp.push(el.answer)
                            }
                        )
                        tmp.unshift('Choose something')
                        setProfilingAnswers(tmp)
                    }
                })
                break
            case 'strings':
                console.log('Setter riktig content')

                content = ['LOWERCASE_EQUALS']
                break
            default:
                content = [...feedTypes.criteriaOperators]
                break
        }
        content.unshift('Choose something')
        setCriteriaOperators(content)
    }
    const updateUser = (fav: string[]) => {
        let _userData = { ...userData }
        _userData = { ...userData, favouriteSelection: fav }
        const stringified = JSON.stringify(JSON.stringify(_userData))

        updateUserData(stringified)
    }

    useEffect(() => {
        if (!feedTypes) dispatch(getFeedTypes())
    }, [])

    useEffect(() => {
        getCriteriaOperators('')
        getCriteriaCodes()
    }, [feedTypes])

    useEffect(() => {
        if (feedActionType === 'QUESTION') {
            setSendAll(true)
        } else {
            setSendAll(false)
        }
    }, [feedActionType])

    useEffect(() => {
        getCriteriaCodes()
    }, [showAll])

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        addSelection(newCriterium)
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.currentTarget
        const { name } = target
        const { value } = target

        setNewCriterium({ ...newCriterium, [name]: value })
    }

    const handleSelectCriteria = async (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (!feedTypes) return

        feedTypes.criteriaCodes.forEach((crit) => {
            if (crit.description === e.target.value) {
                setNewCriterium({
                    ...newCriterium,
                    criteriaCode: crit.criteriaCode,
                })
            }
        })
        checkCriteriaGroup(e.target.value)
    }
    const handleSelectOperator = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewCriterium({ ...newCriterium, operator: e.target.value })
    }
    const handleOperand = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (
            newCriterium.operator === 'ARRAY_CONTAINS' ||
            newCriterium.operator === 'ARRAY_CONTAINS_OR_NO_DATA'
        ) {
            fullProfilingAnswers.forEach((answer) => {
                if (
                    answer.profileDataType ===
                    newCriterium.criteriaCode?.slice(8)
                ) {
                    if (!answer.candidateAnswer.answerOptions) return

                    answer.candidateAnswer.answerOptions.forEach((option) => {
                        if (option.answer === e.target.value) {
                            setNewCriterium({
                                ...newCriterium,
                                operand: option.answerId,
                            })
                        }
                    })
                }
            })
        } else {
            setNewCriterium({ ...newCriterium, operand: e.target.value })
        }
    }

    const handleAddCriteria = () => {
        addSelection(newCriterium)
    }

    const handleFavourites = () => {
        if (showAll) {
            if (favCodes.length === 1) {
                setFavCodes([])
            }
            favCodes.forEach((fav) => {
                if (fav === newCriterium.criteriaCode) {
                    const index = favCodes.findIndex((match) => match === fav)
                    favCodes.splice(index, 1)
                }
            })
        } else {
            let addFav = true
            if (newCriterium.criteriaCode) {
                favCodes.forEach((fav) => {
                    if (fav === newCriterium.criteriaCode) {
                        alert('Already in favourites!')
                        addFav = false
                        return
                    }
                })
                if (addFav) {
                    favCodes.push(newCriterium.criteriaCode)
                }
            }
        }
        setUserData({ ...userData, favouriteSelection: favCodes })
        updateUser(favCodes)
        getCriteriaCodes()
    }

    const setOperand = () => {
        if (profilingAnswers.length > 0) {
            return (
                <select
                    className="criteria-form"
                    name="criteriaCode"
                    onChange={handleOperand}
                >
                    {profilingAnswers.map((answer, key) => {
                        return (
                            <option
                                id="criteria-option"
                                key={key}
                                value={answer}
                            >
                                {answer}
                            </option>
                        )
                    })}
                </select>
            )
        } else if (newCriterium.criteriaCode === 'LAST_LOGGED_IN_TIME') {
            return (
                <Calendar
                    // className={'date-picker'}
                    onChange={(value: Date) =>
                        setNewCriterium({
                            ...newCriterium,
                            operand: value.toISOString().slice(0, -1),
                        })
                    }
                    defaultValue={new Date()}
                />
            )
        } else {
            return (
                <input
                    className="operand-form"
                    type="text"
                    placeholder="Add operand"
                    name="operand"
                    value={newCriterium.operand}
                    onChange={handleChange}
                />
            )
        }
    }
    const getMyEmail = async () => {
        const email = await getEmail()
        const tmp = {
            criteriaCode: 'USER_EMAIL',
            operator: 'LOWERCASE_EQUALS',
            operand: email,
        }
        addSelection(tmp)
    }

    const sendAllCriteria = () => {
        if (!sendAll) {
            return (
                <div onSubmit={handleSubmit} className="select-creteria-form">
                    <hr id="criteria-line" />
                    <Button id="email-btn" onClick={getMyEmail}>
                        Get my email
                    </Button>
                    <div id="fav-btns">
                        <Button
                            id="show-change-btn"
                            onClick={() => setShowAll(!showAll)}
                        >
                            {showAll ? 'Show all' : 'Show favourites'}
                        </Button>
                        <Button
                            id="add-favourites-btn"
                            onClick={handleFavourites}
                        >
                            {showAll ? 'Remove favourite' : 'Add favourite'}
                        </Button>
                    </div>
                    <div>Selection Criteria</div>
                    <select
                        className="creteria-form"
                        name="criteriaCode"
                        onChange={handleSelectCriteria}
                    >
                        {criteriaCodes.map((code: string, key: number) => {
                            return (
                                <option key={key} value={code}>
                                    {code}
                                </option>
                            )
                        })}
                    </select>
                    <select
                        className="operator-form"
                        name="operator"
                        onChange={handleSelectOperator}
                    >
                        {criteriaOperators.map((type: string, key: number) => {
                            return (
                                <option key={key} value={type}>
                                    {type}
                                </option>
                            )
                        })}
                    </select>
                    {setOperand()}
                    <Button onClick={handleAddCriteria} id="criteria-btn">
                        Add the criteria
                    </Button>
                    <hr id="criteria-line" />
                </div>
            )
        } else {
            return (
                <div id="send-all-container">
                    <h4 id="send-all-header">
                        Will now send to everyones feed!
                    </h4>
                </div>
            )
        }
    }

    return (
        <div id="creteria-container">
            <div id="send-all-check">
                <Form.Check
                    checked={sendAll === true}
                    className="checkbox"
                    id="important"
                    type={'checkbox'}
                    name={'sendAll'}
                    value={'sendAll'}
                    onChange={() => setSendAll(!sendAll)}
                />
                <label id="send-all-label">Send to all!</label>
            </div>
            {sendAllCriteria()}
            <ul id="criteria-ul">
                {criteriumList.map((item: ICriterium, key: number) => {
                    //Legge til så den tar riktig prop
                    return (
                        <CriteriumItem
                            deleteCriterium={deleteCriterium}
                            key={key}
                            index={key}
                            criterium={item}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

export default SelectionCriteria
